import { ButtonV2, RadioButton, Text } from "@asayinc/component-library";
import { Box, Stack, capitalize, useTheme } from "@mui/material";

import { Communication, ProxyCommunication } from "src/types";

interface IProposalsProps {
  communication: Communication;
}

export function Proposals({ communication }: IProposalsProps) {
  const theme = useTheme();

  const { proposals } = communication as ProxyCommunication;
  const groupedProposals = getGroupedProposals(proposals);

  return (
    <Stack maxWidth="1000px" mb={8}>
      {Object.keys(groupedProposals).map((key: string) => {
        const groupedProposal = groupedProposals[key];
        const exampleProposal = groupedProposal[0];
        const { title, voteChoices } = exampleProposal;
        return (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              p: 6,
              mb: 8,
            }}
            className="proposal"
            id={`group-${key}`}
            key={`group-${key}`}
          >
            <Text emphasis="high" variant="subtitle1">
              {`${key}. ${title}`}
            </Text>
            {/* <ChipV2
              variant="secondary"
              selected
              label="Board Proposal"
              sx={{ my: 3 }}
              size="medium"
            /> */}
            {groupedProposal.length > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                  },
                }}
              >
                {voteChoices.map((choice, index) => {
                  return (
                    <Box
                      sx={{
                        border: "1px solid #E0E0E0",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        px: 3,
                        flexGrow: 1,
                        mr: 3,
                        py: 1,
                        "&:last-child": {
                          mr: 0,
                        },
                        [theme.breakpoints.down("md")]: {
                          mr: 0,
                          mt: 2,
                          "&:first-child": {
                            mt: 0,
                          },
                        },
                      }}
                      key={index}
                    >
                      <RadioButton color="primary" size="small" disableRipple />
                      <Text variant="body2">{capitalize(choice) + " all"}</Text>
                    </Box>
                  );
                })}
              </Box>
            )}
            {groupedProposal.map((proposal, index) => {
              const { details, voteChoices } = proposal;
              return (
                <Box
                  key={`grouped-proposal-${index}`}
                  sx={{
                    mt: 6,
                    borderBottom: "1px solid #e0e0e0",
                    pb: 6,
                    "&:last-child": { borderBottom: "none" },
                  }}
                >
                  <Text sx={{ mb: 6 }} variant="body2">
                    {groupedProposal.length > 1
                      ? `${String.fromCharCode(65 + index)}. `
                      : ""}
                    {details}
                  </Text>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                      },
                    }}
                  >
                    {voteChoices.map((choice, index) => {
                      return (
                        <Box
                          sx={{
                            border: "1px solid #E0E0E0",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            px: 3,
                            flexGrow: 1,
                            mr: 3,
                            "&:last-child": {
                              mr: 0,
                            },
                            [theme.breakpoints.down("md")]: {
                              mr: 0,
                              mt: 2,
                              "&:first-child": {
                                mt: 0,
                              },
                            },
                          }}
                          key={index}
                        >
                          <RadioButton
                            color="primary"
                            size="small"
                            disableRipple
                          />
                          <Text variant="body2">{capitalize(choice)}</Text>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
      <ButtonV2 variant="primary">Submit</ButtonV2>
    </Stack>
  );
}

export function getGroupedProposals(
  proposals: ProxyCommunication["proposals"]
) {
  return proposals.reduce((groupedProposals, proposal) => {
    const { groupNumber } = proposal;
    if (!groupedProposals[groupNumber]) {
      groupedProposals[groupNumber] = [];
    }
    groupedProposals[groupNumber].push(proposal);
    return groupedProposals;
  }, {} as Record<string, ProxyCommunication["proposals"]>);
}
