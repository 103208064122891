import {
  Communication,
  CorporateActionCommunication,
  ProxyCommunication,
} from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";
import { toWords } from "number-to-words";

export function getEventDescription(communication: Communication) {
  const { type } = communication;
  const { issuerName } = communication.security;

  if (type === "corporate_action_election") {
    const {
      corporateActionEvent: { electionCutoff },
    } = communication as CorporateActionCommunication;
    return `${issuerName} has announced a voluntary event. As a shareholder, you're eligible to participate. However, participation is not required. Additional resources to help you make an informed decision are available on the next page. You can submit or edit your election anytime before the offer expires on ${formatDate(
      { date: electionCutoff }
    )}.`;
  }

  const {
    proposals,
    proxyEvent: { meeting, type: proxyType, voteCutoff },
  } = communication as ProxyCommunication;
  const proposalGroups = [...new Set(proposals.map((p) => p.groupNumber))];
  const formattedMeetingDate = formatDate({
    date: meeting?.meetingDate,
    timezone: meeting?.meetingTimezone,
  });

  switch (type) {
    case "proxy_vote":
      if (proxyType === "annual_meeting") {
        return `This annual meeting for ${issuerName} has ${toWords(
          proposalGroups.length
        )} proposal${
          proposalGroups.length > 1 ? "s" : ""
        } up for approval. Additional resources to help you make an informed vote are available on the next page along with the ballot. Votes will be submitted at the meeting on ${formattedMeetingDate}. Your vote helps determine how the company will be run for the following year — vote your shares now.`;
      } else if (proxyType === "contested_annual_meeting") {
        return `This annual meeting for ${issuerName} is being contested by ${meeting?.contestingParty}. The dissident's materials are available on the next page to help inform your vote. Votes will be submitted at the meeting on ${formattedMeetingDate} — vote your shares now.`;
      } else if (proxyType === "contested_special_meeting") {
        return `This special meeting for ${issuerName} is being contested by ${meeting?.contestingParty}. The dissident's materials are available on the next page to help inform your vote. Votes will be submitted at the meeting on ${formattedMeetingDate} — vote your shares now.`;
      } else {
        return `This special meeting for ${issuerName} has ${toWords(
          proposalGroups.length
        )} proposal${
          proposalGroups.length > 1 ? "s" : ""
        } up for approval. Additional resources to help you make an informed vote are available on the next page along with the ballot. Votes will be submitted at the meeting on ${formattedMeetingDate}. Special meetings may play an important role in company strategy — vote your shares now.`;
      }
    case "consent_solicitation":
      return `Your investment is soliciting your consent to make a change to one or more of its securities. Supplemental materials to help you make an informed choice are available on the next page along with your response form. All responses will be submitted by ${formatDate(
        { date: voteCutoff }
      )}. Your action helps determine how the company will be run for the following year — respond with your shares now.`;
    case "proxy_informational":
      return "Unexpected proxy_informational communication";
    default:
      return "Unknown communicaiton type provided";
  }
}

/**
 * Annual (Contested)
 * This ${type} for ${issuerName} is being contested by ${contestingParty}. The dissident's materials are available on the next page to help inform your vote. Votes will be submitted at the meeting on ${formatDate({date: voteCutoffDate})} — vote your shares now.
 *
 * Special (Contested)
 * This ${type} for ${issuerName} is being contested by ${contestingParty}. The dissident's materials are available on the next page to help inform your vote. Votes will be submitted at the meeting on ${formatDate({date: voteCutoffDate})} — vote your shares now.
 */
