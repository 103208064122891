import { ButtonV2, Text, Tooltip } from "@asayinc/component-library";
import { Box, Dialog, DialogContent, Stack, useTheme } from "@mui/material";
import { isProxyCommunication } from "src/pages/CommunicationDetails/utils";
import { getUniqueAccountNumbersFromCommunications } from "src/pages/Communications/utils";
import { Communication } from "src/types";

interface IProfileDialogProps {
  communication: Communication;
  isOpen: boolean;
  onClose: () => void;
  onMyBallotsClick: () => void;
}

export function ProfileDialog({
  communication,
  isOpen,
  onClose,
  onMyBallotsClick,
}: IProfileDialogProps) {
  const theme = useTheme();
  const accountNumbers = getUniqueAccountNumbersFromCommunications(
    communication ? [communication] : []
  );
  const [firstAccountNumber, ...rest] = accountNumbers;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            top: 48,
          },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: "0px",
          margin: "0px",
          position: "absolute",
          top: 48,
          width: "100%",
          borderTop: `6px solid ${theme.palette.l1.main}`,
        },
      }}
    >
      <DialogContent>
        <Stack
          sx={{
            my: 6,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            variant="h5"
            sx={{ textAlign: "center" }}
            loadingProps={{
              sx: {
                width: "280px",
              },
            }}
          >
            {communication?.customer?.name || undefined}
          </Text>
          {accountNumbers.length > 0 ? (
            <Box sx={{ display: "flex", mt: 2 }} data-testid="account-numbers">
              <Text variant="body2" sx={{ textAlign: "center" }}>
                Account: {firstAccountNumber}
              </Text>
              {accountNumbers.length > 1 && (
                <Tooltip
                  title={rest.join(", ")}
                  sx={{ ml: 1, cursor: "default" }}
                  placement="top"
                >
                  <Text variant="body2">{`and ${
                    accountNumbers.length - 1 + " more"
                  }`}</Text>
                </Tooltip>
              )}
            </Box>
          ) : null}
        </Stack>
        {isProxyCommunication(communication) && (
          <Stack>
            <ButtonV2
              onClick={onMyBallotsClick}
              sx={{ mb: 6, mx: 5 }}
              data-testid="all-ballots-button"
            >
              All ballots
            </ButtonV2>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
