import { Box, Stack, useTheme } from "@mui/material";
import { ButtonV2, LoadingSkeleton } from "@asayinc/component-library";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "../Card";
import {
  getElectedToVoteInPersonOptions,
  getMeetingAttendanceTypeOptions,
  getVoteCardDescription,
} from "../../utils";
import {
  useCommunicationWithTrackingQuery,
  useSubmitMeetingAttendanceMutation,
} from "src/store/warrenG/communication";
import { ProxyCommunication } from "src/types";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { CustomFormRadioGroup } from "src/components/molecules/CustomFormRadioGroup";
import { useEffect } from "react";
import { getCardTitle, isDaysBeforeDate } from "./utils";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { useTrackingParams } from "src/hooks/useTrackingParams";

type MeetingAttendanceForm = {
  meetingAttendanceType:
    | "in_person"
    | "virtual"
    | "telephone"
    | "private"
    | null;
  electedToVoteInPerson: "true" | "false" | null;
};

export function AttendMeetingForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const trackingParams = useTrackingParams();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const {
    isLoading,
    data: communication,
    refetch,
  } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  const proxyCommunication = communication as ProxyCommunication;
  const form = useForm<MeetingAttendanceForm>({
    defaultValues: {
      meetingAttendanceType:
        proxyCommunication?.proxyEvent?.meeting.formats.length === 1
          ? proxyCommunication?.proxyEvent?.meeting.formats[0].format
          : null,
      electedToVoteInPerson: null,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
    reset,
    watch,
  } = form;
  const formatType = watch("meetingAttendanceType");
  const [attendMeeting, { isLoading: isSubmitting }] =
    useSubmitMeetingAttendanceMutation();

  useEffect(() => {
    if (communication) {
      reset({
        meetingAttendanceType:
          proxyCommunication?.proxyEvent?.meeting.formats.length === 1
            ? proxyCommunication?.proxyEvent?.meeting.formats[0].format
            : null,
        electedToVoteInPerson: null,
      });
    }
  }, [communication, reset, proxyCommunication]);

  if (isLoading) {
    return (
      <Card sx={{ mb: 6, width: "100%" }}>
        <Box sx={{ display: "flex", my: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
      </Card>
    );
  }

  const {
    meetingAttendance,
    proxyEvent: { meeting },
  } = communication as ProxyCommunication;
  const { formats, meetingDate } = meeting;

  const selectedFormat = formats.find((format) => format.format === formatType);
  const showAttendanceCard = formats.length > 1;
  const showVotingCard = formatType && selectedFormat;
  const submittedMeetingAttendance = meetingAttendance
    ? !!meetingAttendance.format
    : false;
  const isPastDeadline = !isDaysBeforeDate(meetingDate, 3);

  const handleSubmitMeetingAttendance = async (formData: FieldValues) => {
    const { meetingAttendanceType, electedToVoteInPerson } = formData;
    try {
      await attendMeeting({
        brokerId,
        communicationId,
        payload: {
          legalProxy: electedToVoteInPerson === "true",
          format: meetingAttendanceType
            ? meetingAttendanceType
            : formats[0].format,
        },
        ...trackingParams,
      });
      await refetch();
      track({
        name: TrackEventType.AttendMeetingSubmitRegistration,
        legalProxy: electedToVoteInPerson === "true",
        format: meetingAttendanceType
          ? meetingAttendanceType
          : formats[0].format,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
      navigate(
        `/brokers/${brokerId}/communications/${communicationId}/attend-meeting-confirmation` +
          location.search
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackClick = () => {
    track({
      name: TrackEventType.AttendMeetingBackToCommunicationDetails,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    navigate(-1);
  };

  return (
    <>
      <FormProvider {...form}>
        <form
          id="meeting-attendance-form"
          onSubmit={handleSubmit(handleSubmitMeetingAttendance)}
        >
          {showAttendanceCard && (
            <Card
              sx={{ mb: 8 }}
              testId="meeting-attendance-card"
              title="Meeting attendance"
              description={getCardTitle(formats)}
            >
              <CustomFormRadioGroup
                options={getMeetingAttendanceTypeOptions(proxyCommunication)}
                name="meetingAttendanceType"
                isDisabled={isPastDeadline}
                isRequired={formats.length > 1 ? true : false}
                isVertical={true}
              />
            </Card>
          )}
          {showVotingCard && (
            <Card
              sx={{ mb: 5, [theme.breakpoints.down("sm")]: { mb: 0 } }}
              description={getVoteCardDescription(
                proxyCommunication,
                selectedFormat
              )}
              testId="meeting-voting-card"
              title="Vote preference"
            >
              <CustomFormRadioGroup
                isDisabled={isPastDeadline}
                isRequired={true}
                isVertical={true}
                name="electedToVoteInPerson"
                options={getElectedToVoteInPersonOptions(selectedFormat)}
              />
            </Card>
          )}
        </form>
      </FormProvider>
      <Stack
        sx={{
          position: "sticky",
          bottom: 0,
          right: 0,
        }}
      >
        <GradientBlock height="20px" />
        <Stack
          sx={{
            backgroundColor: "#f0f0f0",
            pb: 8,
            [theme.breakpoints.down("sm")]: { pb: 6 },
          }}
        >
          <ButtonV2
            variant="primary"
            sx={{ mb: 2 }}
            disabled={!isValid || submittedMeetingAttendance || isPastDeadline}
            loading={isSubmitting}
            form="meeting-attendance-form"
            type="submit"
          >
            Submit registration
          </ButtonV2>
          <ButtonV2 variant="secondary" onClick={handleBackClick}>
            Back to ballot
          </ButtonV2>
        </Stack>
      </Stack>
    </>
  );
}
