import { Box, Stack, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { EventHeader } from "../Communication/components/EventHeader";
import { MeetingInfoCard } from "./components/MeetingInfoCard";
import { AttendMeetingForm } from "./components/AttendMeetingForm";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";

export function AttendMeeting() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { data: communication } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  useEffect(() => {
    // If customer already submitted a meeting attendance,
    // redirect to the details page
    if (communication) {
      const { meetingAttendance } = communication;
      if (meetingAttendance) {
        navigate(
          `/brokers/${brokerId}/communications/${communicationId}/details` +
            location.search
        );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication, brokerId, communicationId, navigate]);

  return (
    <Stack alignItems="center" mt={12} height="100%">
      <Box
        sx={{
          width: "100%",
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            borderBottom: `6px solid ${theme.palette.l1.main}`,
          },
        }}
      />
      <EventHeader sx={{ py: 8, px: 5, mb: 8 }} hideBanner />
      <Stack
        sx={{
          maxWidth: "752px",
          width: "100%",
          flexGrow: 1,
          [theme.breakpoints.down("sm")]: {
            px: 4,
          },
        }}
      >
        <MeetingInfoCard />
        <AttendMeetingForm />
      </Stack>
    </Stack>
  );
}
