import { ButtonV2, Dialog, Text } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  useCommunicationWithTrackingQuery,
  useSubmitElectionMutation,
} from "src/store/warrenG/communication";
import { CorporateActionCommunication } from "src/types";
import { getDefaultValues, getParticipateQuestion } from "./utils";
import { ElectionChoices } from "./ElectionChoices";
import { ChoiceDetail } from "src/types/Communication";
import { ElectionResultPayload } from "src/store/warrenG/communication/types";
import { isPassedCutoffDate } from "src/pages/Communications/utils";
import { LoadingProposalCards } from "../LoadingProposalCards";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { useTrackingParams } from "src/hooks/useTrackingParams";
import { useBrokerQuery } from "src/store/warrenG/broker";

export function CorporateActionForm() {
  const trackingParams = useTrackingParams();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { data: broker } = useBrokerQuery(brokerId);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [three02bDialogOpen, setThree02bDialogOpen] = useState(false);
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const [submitElection, { isSuccess, isLoading }] =
    useSubmitElectionMutation();
  const form = useForm<{
    choice: string | undefined;
    choiceDetails: ChoiceDetail[];
  }>({
    mode: "onBlur",
    defaultValues: getDefaultValues(
      communication as CorporateActionCommunication
    ),
  });

  const {
    offer: { detail, id, electionChoices, title },
    corporateActionEvent: { type, subjectTo302b },
  } = communication as CorporateActionCommunication;
  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    getValues,
  } = form;

  useEffect(() => {
    if (isSuccess && communication) {
      navigate(
        `/brokers/${brokerId}/communications/${communication.id}/success` +
          location.search,
        {
          state: {
            backLink:
              `/brokers/${brokerId}/communications/${communication.id}/details` +
              location.search,
            customerId: communication.customer.id,
            previousIssuerName: communication.security.issuerName,
          },
        }
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigate, brokerId, communication]);

  if (isCommunicationLoading) {
    return <LoadingProposalCards />;
  }

  if (!communication) {
    return <Box>Communication not found</Box>;
  }

  const handleVoteSubmit = async (formData: FieldValues) => {
    const { choice, choiceDetails } = formData;
    const payload = choiceDetails.map((choiceDetail: ChoiceDetail) => {
      return {
        choice,
        offerId: id,
        positionId: choiceDetail.positionId,
        options: {
          sharesElected: choiceDetail.sharesElected || null,
          shareholderBid: choiceDetail.shareholderBid || null,
          rightsElected: choiceDetail.rightsElected || null,
          oversubscriptionElected: choiceDetail.oversubscriptionElected || null,
        },
      };
    });

    if (choice === "no" || type === "odd_lot_offer") {
      payload.forEach((result: ElectionResultPayload) => {
        result.options = {};
      });
    }

    try {
      await submitElection({
        brokerId,
        communicationId: communication.id,
        payload,
        ...trackingParams,
      });
      track({
        name: TrackEventType.CommunicationDetailsSubmit,
        isProxy: false,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Dialog
        open={three02bDialogOpen}
        handleClose={() => setThree02bDialogOpen(false)}
        title="Withholding Notice"
        content={
          <Text variant="body2">
            By electing on this corporate action event you confirm that the
            payment should be treated as gross proceeds and not dividend income.
            Dividend income would be subject to up to 30% US NRA tax
            withholding. For more information in regards to gross proceeds vs
            dividend income treatment and IRS Section 302(b), please consult
            your own tax, legal, and accounting advisers.
          </Text>
        }
        buttonActions={[
          { onClick: handleSubmit(handleVoteSubmit), label: "Confirm" },
        ]}
      />
      <FormProvider {...form}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const shouldDisplay302bDialog =
              broker?.is302bEligible &&
              subjectTo302b &&
              getValues("choice") !== "no";

            shouldDisplay302bDialog
              ? setThree02bDialogOpen(true)
              : handleSubmit(handleVoteSubmit)();
          }}
          id="offer-form"
          style={{ maxWidth: "1000px", flexGrow: 1 }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              p: 6,
            }}
          >
            <Text variant="subtitle1" sx={{ mb: 3 }}>
              {title}
            </Text>
            <Text variant="subtitle2" sx={{ mb: 3 }}>
              {detail}
            </Text>
            <Text variant="body2" sx={{ mb: 6 }}>
              {getParticipateQuestion(
                communication as CorporateActionCommunication
              )}
            </Text>
            <ElectionChoices
              communication={communication as CorporateActionCommunication}
              electionChoices={electionChoices}
            />
          </Box>
          <Stack
            sx={{
              position: "sticky",
              bottom: 0,
              right: 0,
            }}
          >
            <GradientBlock height="20px" />
            <Stack
              sx={{
                backgroundColor: "#f0f0f0",
                pb: 8,
                [theme.breakpoints.down("sm")]: { pb: 6 },
              }}
            >
              <ButtonV2
                disabled={
                  !isDirty ||
                  !isValid ||
                  isSubmitting ||
                  isPassedCutoffDate(communication)
                }
                form="offer-form"
                loading={isLoading}
                type="submit"
                variant="primary"
              >
                Submit
              </ButtonV2>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
}
