import { ButtonV2, Text } from "@asayinc/component-library";
import { Box, Stack, capitalize, useTheme } from "@mui/material";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  Proposal,
  ProxyCommunication,
  VoteResult,
} from "src/types/Communication";
import { getGroupedProposals } from "../Proposals/Proposals";
import { SelectAllProposals } from "src/pages/CommunicationDetails/components/SelectAllProposals";
import { ProposalCard } from "../ProposalCard";
import {
  useCommunicationWithTrackingQuery,
  useSubmitVoteMutation,
} from "src/store/warrenG/communication";
import { VoteResultPayload } from "src/store/warrenG/communication/types";
import { isPassedCutoffDate } from "src/pages/Communications/utils";
import { LoadingProposalCards } from "../LoadingProposalCards";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useState } from "react";
import { WithdrawDialog } from "../WithdrawDialog";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { useTrackingParams } from "src/hooks/useTrackingParams";
import { useLazyTrackEventQuery } from "src/store/consumer/messages/api";

export function ProposalForm() {
  const trackingParams = useTrackingParams();
  const [searchParams] = useSearchParams();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { proposals, voteResults } = communication as ProxyCommunication;
  const form = useForm({
    defaultValues: getDefaultValues(proposals, voteResults),
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
    setValue,
    getValues,
    watch,
  } = form;
  const groupedProposals = getGroupedProposals(proposals);
  const [trackEvent] = useLazyTrackEventQuery();
  const [submitVote, { isLoading }] = useSubmitVoteMutation();
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  if (isCommunicationLoading) {
    return <LoadingProposalCards />;
  }

  if (!communication) {
    return <Box>Communication not found</Box>;
  }

  const handleOnAllSelect = (proposalId: string, value: string) => {
    setValue(proposalId as never, value as never, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleToggleWithdrawDialog = () => {
    // Want to only track when we open withdraw dialog
    if (!isWithdrawDialogOpen) {
      track({
        name: TrackEventType.CommunicationDetailsWithdrawVote,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
    } else {
      track({
        name: TrackEventType.CommunicationDetailsCancelWithdraw,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
    }

    setIsWithdrawDialogOpen((prev) => !prev);
  };

  const handleVoteSubmit = async (formData: FieldValues) => {
    const { positions } = communication as ProxyCommunication;
    const results: VoteResultPayload[] = [];
    const proposalIds = Object.keys(formData);
    positions.forEach((position) => {
      const { id } = position;
      proposalIds.forEach((proposalId) => {
        results.push({
          positionId: id,
          proposalId,
          choice: formData[proposalId],
        });
      });
    });
    try {
      await submitVote({
        brokerId: brokerId,
        communicationId: communication.id,
        payload: results,
        ...trackingParams,
      });

      // If user has a vote solicitation message id (vs) then record the vote submission
      const voteSolicitationMessageId = searchParams.get("vs");
      if (voteSolicitationMessageId) {
        trackEvent({
          messageId: voteSolicitationMessageId,
          communicationId,
          type: "voted",
        });
      }

      track({
        name: TrackEventType.CommunicationDetailsSubmit,
        isProxy: true,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
      // Store the search params in sessionStorage
      // for when the user returns to the same communication
      sessionStorage.setItem(communicationId, location.search);
      navigate(`/brokers/${brokerId}/customers/${communication.customer.id}`, {
        state: {
          backLink:
            `/brokers/${brokerId}/communications/${communication.id}/details` +
            location.search,
          previousIssuerName: communication.security.issuerName,
        },
      });
    } catch {
      console.log("error");
    }
  };
  const values = getValues();
  const numberSelected = Object.values(values).filter(
    (value) => !!value
  ).length;
  const totalProposals = Object.keys(values).length;
  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(handleVoteSubmit)}
        id="proposal-form"
        style={{ maxWidth: "1000px", flexGrow: 1 }}
      >
        {Object.keys(groupedProposals).map((key: string, index: number) => {
          const groupedProposal = groupedProposals[key];
          const exampleProposal = groupedProposal[0];
          const { title, voteChoices } = exampleProposal;
          return (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                p: 6,
                mb: Object.keys(groupedProposals).length - 1 === index ? 5 : 8,
                [theme.breakpoints.down("sm")]: {
                  mb:
                    Object.keys(groupedProposals).length - 1 === index ? 0 : 4,
                },
              }}
              className="proposal"
              id={`group-${key}`}
              key={`group-${key}`}
            >
              <Text emphasis="high" variant="subtitle1">
                {`${key}. ${title}`}
              </Text>
              {groupedProposal.length > 1 && (
                <SelectAllProposals
                  communication={communication}
                  formValues={watch()}
                  groupedProposal={groupedProposal}
                  isAttendingMeeting={
                    !!communication.meetingAttendance?.legalProxy
                  }
                  isPassedCutoffDate={isPassedCutoffDate(communication)}
                  onChange={handleOnAllSelect}
                  options={voteChoices.map((choice) => ({
                    label: capitalize(choice).replaceAll("_", " ") + " all",
                    value: choice,
                  }))}
                />
              )}
              {groupedProposal.map((proposal, index) => {
                return (
                  <ProposalCard
                    isAttendingMeeting={
                      !!communication.meetingAttendance?.legalProxy
                    }
                    isPassedCutoffDate={isPassedCutoffDate(communication)}
                    key={`grouped-proposal-${index}`}
                    letterIndex={index}
                    proposal={proposal}
                  />
                );
              })}
            </Box>
          );
        })}
        <Stack
          sx={{
            position: "sticky",
            bottom: 0,
            right: 0,
          }}
        >
          <GradientBlock height="20px" />
          <Stack
            sx={{
              backgroundColor: "#f0f0f0",
              pb: 8,
              [theme.breakpoints.down("sm")]: { pb: 6 },
            }}
          >
            <ButtonV2
              disabled={
                !isDirty ||
                !isValid ||
                isSubmitting ||
                isPassedCutoffDate(communication)
              }
              form="proposal-form"
              loading={isLoading}
              type="submit"
              variant="primary"
            >
              {totalProposals === numberSelected
                ? "Submit"
                : `${totalProposals - numberSelected} proposal${
                    totalProposals - numberSelected > 1 ? "s" : ""
                  } left`}
            </ButtonV2>
            {voteResults.length > 0 && (
              <ButtonV2
                data-testid="withdraw"
                disabled={isPassedCutoffDate(communication)}
                onClick={handleToggleWithdrawDialog}
                sx={{ mt: 2 }}
                variant="warningSecondary"
              >
                Withdraw my vote
              </ButtonV2>
            )}
          </Stack>
          <WithdrawDialog
            customerId={communication.customer.id}
            isOpen={isWithdrawDialogOpen}
            issuerName={communication.security.issuerName}
            onClose={handleToggleWithdrawDialog}
          />
        </Stack>
      </form>
    </FormProvider>
  );
}

function getDefaultValues(proposals: Proposal[], voteResults: VoteResult[]) {
  return proposals.reduce((defaultValues, proposal) => {
    defaultValues[proposal.id] = null;
    if (getCurrentSelection(proposal.id, voteResults)) {
      defaultValues[proposal.id] = getCurrentSelection(
        proposal.id,
        voteResults
      );
    }
    return defaultValues;
  }, {});
}

function getCurrentSelection(proposalId: string, voteResults: VoteResult[]) {
  const result = voteResults.find(
    (voteResult) => voteResult.proposalId === proposalId
  );
  return result ? result.choice : undefined;
}
