import { createBrowserRouter } from "react-router-dom";

import { App } from "./App";
import { Communication } from "./pages/Communication";
import { Communications } from "./pages/Communications";
import { CatchAll } from "./pages/CatchAll";
import { CommunicationDetails } from "./pages/CommunicationDetails";
import { ElectionConfirmation } from "./pages/ElectionConfirmation";
import { AttendMeeting } from "./pages/AttendMeeting";
import { NavbarContainer } from "./components/containers/NavbarContainer";
import { BrokerContainer } from "./components/containers/BrokerContainer";
import { AttendMeetingConfirmation } from "./pages/AttendMeetingConfirmation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "brokers/:brokerId",
        element: <BrokerContainer />,
        children: [
          {
            path: "communications/:communicationId",
            element: <NavbarContainer />,
            children: [
              {
                path: "",
                element: <Communication />,
              },
              {
                path: "attend-meeting",
                element: <AttendMeeting />,
              },
              {
                path: "attend-meeting-confirmation",
                element: <AttendMeetingConfirmation />,
              },
              {
                path: "details",
                element: <CommunicationDetails />,
              },
              {
                path: "success",
                element: <ElectionConfirmation />,
              },
            ],
          },
          {
            path: "customers/:customerId",
            element: <Communications />,
          },
          {
            path: "*",
            element: <CatchAll />,
          },
        ],
      },
      {
        path: "/",
        Component: () => {
          window.location.href = "https://www.saytechnologies.com/";
          return null;
        },
      },
    ],
  },
]);
