import {
  ICommunicationParams,
  ICommunicationsParams,
  IPaginationResponse,
  ITrackingParams,
} from "../../types";
import { Communication } from "src/types";
import { MeetingAttendance } from "src/types/MeetingAttendance";
import { ElectionResultPayload, VoteResultPayload } from "./types";
import { warrenGApi } from "../rootApi";

export const communicationApi = warrenGApi.injectEndpoints({
  endpoints: (builder) => ({
    communications: builder.query<
      IPaginationResponse<Communication>,
      ICommunicationsParams
    >({
      query: ({ brokerId, customerId, isOpen, isClosed, isActionable }) => ({
        url: `${brokerId}/customers/${customerId}/communications/`,
        params: {
          is_open: isOpen,
          is_closed: isClosed,
          is_actionable: isActionable,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Communication" as const,
                id,
              })),
              "Communication",
            ]
          : ["Communication"],
    }),
    communication: builder.query<
      Communication,
      ICommunicationParams & ITrackingParams
    >({
      query: ({ brokerId, communicationId, ...trackingParams }) => {
        return {
          url: `${brokerId}/communications/${communicationId}/`,
          params: trackingParams,
        };
      },
      providesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
    submitVote: builder.mutation<
      Communication,
      {
        payload: VoteResultPayload[];
      } & ICommunicationParams &
        ITrackingParams
    >({
      query: ({ brokerId, communicationId, payload, ...trackingParams }) => ({
        url: `${brokerId}/communications/${communicationId}/vote-results/`,
        method: "POST",
        body: payload,
        params: trackingParams,
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
    submitElection: builder.mutation<
      Communication,
      {
        payload: ElectionResultPayload;
      } & ICommunicationParams &
        ITrackingParams
    >({
      query: ({ brokerId, communicationId, payload, ...trackingParams }) => ({
        url: `${brokerId}/communications/${communicationId}/election-results/`,
        method: "POST",
        body: payload,
        params: trackingParams,
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
    submitMeetingAttendance: builder.mutation<
      Omit<MeetingAttendance, "created" | "id" | "document">,
      {
        payload: Omit<MeetingAttendance, "created" | "document" | "id">;
      } & ICommunicationParams &
        ITrackingParams
    >({
      query: ({ brokerId, communicationId, payload, ...trackingParams }) => ({
        url: `${brokerId}/communications/${communicationId}/meeting-attendance/`,
        method: "POST",
        body: payload,
        params: trackingParams,
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
    deleteVoteResult: builder.mutation<
      unknown,
      ICommunicationParams & ITrackingParams
    >({
      query: ({ brokerId, communicationId, ...trackingParams }) => ({
        url: `${brokerId}/communications/${communicationId}/vote-results/`,
        method: "DELETE",
        params: trackingParams,
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
    deleteElectionResult: builder.mutation<
      unknown,
      ICommunicationParams & ITrackingParams
    >({
      query: ({ brokerId, communicationId, ...trackingParams }) => ({
        url: `${brokerId}/communications/${communicationId}/election-results/`,
        method: "DELETE",
        params: trackingParams,
      }),
      invalidatesTags: (_result, _, arg) => [
        { type: "Communication", id: arg.communicationId },
        "Communication",
      ],
    }),
  }),
});

export const {
  useCommunicationsQuery,
  // Note: Use useCommunicationQueryWithTracking instead of useCommunicationQuery
  useCommunicationQuery,
  useSubmitVoteMutation,
  useSubmitElectionMutation,
  useSubmitMeetingAttendanceMutation,
  useDeleteVoteResultMutation,
  useDeleteElectionResultMutation,
} = communicationApi;
