import { Text } from "@asayinc/component-library";

import { StandardInputField } from "src/components/atoms/StandardInputField";
import { Position } from "src/types/Communication";

interface IRightsOfferChoiceDetailsProps {
  position: Position;
  hasSingleAccount: boolean;
  index: number;
  showOversubscription?: boolean;
}

export function RightsOfferChoiceDetails({
  position,
  hasSingleAccount,
  index,
  showOversubscription = false,
}: IRightsOfferChoiceDetailsProps) {
  return (
    <Text variant="body2">
      Account {hasSingleAccount ? "" : index + 1}: ****
      {position.accountIdentifier.replaceAll("*", "")} (total{" "}
      {position.sharesOwned} shares) exchange
      <StandardInputField
        placeholder={`e.g., ${position.sharesOwned}`}
        name={`choiceDetails.${index}.rightsElected`}
        rules={{
          required: {
            value: true,
            message: "Number of rights elected is required",
          },
          max: {
            value: position.sharesOwned,
            message: "Cannot submit more shares than you own",
          },
          min: {
            value: 1,
            message: "You must elect at least one share",
          },
          validate: {
            isWholeNumber: (value) => {
              if (value % 1 !== 0) {
                return "Number of shares must be a whole number";
              }
              return true;
            },
          },
        }}
      />
      shares{" "}
      {showOversubscription && (
        <Text variant="body2">
          and
          <StandardInputField
            placeholder={`e.g., ${position.sharesOwned}`}
            name={`choiceDetails.${index}.oversubscriptionElected`}
            rules={{
              required: {
                value: true,
                message: "Number of rights elected is required",
              },
              max: {
                value: position.sharesOwned,
                message: "Cannot submit more shares than you own",
              },
              min: {
                value: 1,
                message: "You must elect at least one share",
              },
              validate: {
                isWholeNumber: (value) => {
                  if (value % 1 !== 0) {
                    return "Number of shares must be a whole number";
                  }
                  return true;
                },
              },
            }}
          />
          oversubscribed rights elected
        </Text>
      )}
    </Text>
  );
}
