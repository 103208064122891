import { Text } from "@asayinc/component-library";
import { Box, SxProps, useTheme } from "@mui/material";

interface ICardProps {
  children: React.ReactNode;
  description?: string;
  isSmall?: boolean;
  title?: string;
  sx?: SxProps;
  testId?: string;
}

export function Card({
  title,
  description,
  children,
  isSmall = false,
  sx,
  testId,
}: ICardProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 6,
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        ...sx,
      }}
      data-testid={testId}
    >
      {title && (
        <Text
          data-testid="card-title"
          variant={isSmall ? "subtitle2" : "subtitle1"}
          sx={{ color: theme.palette.l4.main, mb: 3 }}
        >
          {title}
        </Text>
      )}
      {description && (
        <Text data-testid="card-description" variant="body2" sx={{ mb: 6 }}>
          {description}
        </Text>
      )}
      {children}
    </Box>
  );
}
