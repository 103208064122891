import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseQueryWithCamelize } from "src/store/utils";

export const warrenGApi = createApi({
  baseQuery: getBaseQueryWithCamelize(
    fetchBaseQuery({
      baseUrl: `${window.config.REACT_APP_WARRENG_URL}/vote/`,
    })
  ),
  reducerPath: "warrenGApi",
  tagTypes: [
    "Broker",
    "Communication",
    "Customer",
    "CustomerPreferences",
    "UserPreference",
  ],
  endpoints: () => ({}),
});
