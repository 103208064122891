import { COLORS, Text } from "@asayinc/component-library";
import { Stack, SxProps, useTheme } from "@mui/material";

interface IAvatarProps {
  name: string | undefined;
  color?: string;
  size?: number;
  sx?: SxProps;
}

export function Avatar({ color, name, size = 24, sx }: IAvatarProps) {
  const theme = useTheme();
  return (
    <Stack
      sx={sx}
      data-testid={`avatar-${name}`}
      bgcolor={!name ? COLORS.P_OUTLINE : color}
      borderRadius="100px"
      minWidth={size}
      minHeight={size}
      height={size}
      alignItems="center"
      justifyContent="center"
    >
      <Text
        variant="subtitle3"
        shade="light"
        data-testid="security-icon"
        sx={{
          color: theme.palette.l6.main,
          mx: name && name?.length > 4 ? 2 : 0,
        }}
      >
        {name}
      </Text>
    </Stack>
  );
}
