import { useSearchParams } from "react-router-dom";
import { ITrackingParams } from "src/store/types";

// This hook returns tracking parameters from the URL
// that are used to track how the user landed on the page.
// These parameters are provided by the backend.
export function useTrackingParams(): ITrackingParams {
  const [searchParams] = useSearchParams();
  return {
    n: searchParams.get("n") || undefined,
    nt: searchParams.get("nt") || undefined,
    am: searchParams.get("am") || undefined,
    vs: searchParams.get("vs") || undefined,
  };
}
