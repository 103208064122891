import { COLORS, IconName, SayIcon, Text } from "@asayinc/component-library";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

interface IEventHeaderBannerProps {
  bannerText: string | undefined;
  iconColor: string;
  iconName: IconName;
  onLegalProxyClick?: () => void;
  showLegalProxy?: boolean;
  testId?: string;
}

export function EventHeaderBanner({
  bannerText,
  iconColor,
  iconName,
  onLegalProxyClick,
  showLegalProxy = false,
  testId,
}: IEventHeaderBannerProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      data-testid={testId}
      sx={{
        display: "flex",
        width: "100%",
        mx: "auto",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          minWidth: "318px",
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          backgroundColor: COLORS.SHADED_GREY,
          py: 4,
          px: 3,
          borderRadius: 2,
          mt: 3,
          flexGrow: 1,
          width: "100%",
          maxWidth: "1000px",
          [theme.breakpoints.down("lg")]: {
            maxWidth: "unset",
            mx: 5,
          },
          [theme.breakpoints.down("sm")]: {
            mx: 0,
          },
        }}
      >
        <Stack alignItems="center" direction={isSmall ? "column" : "row"}>
          <Stack direction="row" alignItems="center">
            <Box sx={{ mr: 3 }}>
              <SayIcon name={iconName} size="24px" color={iconColor} />
            </Box>
            <Text
              variant="body2"
              sx={{ color: COLORS.WHITE }}
              data-testid="meeting-attendance-messsage"
            >
              {bannerText}
            </Text>
          </Stack>
          {showLegalProxy && (
            <Text
              onClick={onLegalProxyClick}
              sx={{
                color: COLORS.WHITE,
                ml: 6,
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                  mt: 3,
                },
              }}
              variant="subtitle2"
            >
              Download Legal Proxy
            </Text>
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          minWidth: "308px",
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      />
    </Box>
  );
}
