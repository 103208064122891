import { Communication, ProxyCommunication } from "src/types";
import { formatDate } from "src/utils/formatDate";

export function getRegistrationSuccessfulCardContent(
  communication: Communication
) {
  const proxyCommunication = communication as ProxyCommunication;
  const { meetingAttendance, security, proxyEvent } = proxyCommunication;
  const { issuerName } = security;
  const { meeting } = proxyEvent;
  const { meetingDate, meetingTimezone } = meeting;

  if (meetingAttendance?.format === "in_person") {
    if (meetingAttendance?.legalProxy && meetingAttendance?.document) {
      return `You've registered to attend and vote at ${issuerName}'s meeting in-person. Please download, print, and bring the Legal Proxy Document below with you on the day of the meeting. Have a great time!`;
    }
    return `You've registered to attend ${issuerName}'s meeting in-person. Please make sure to submit your online ballot here before the meeting, and have a great time!`;
  }

  if (meetingAttendance?.format === "telephone") {
    if (meetingAttendance?.legalProxy) {
      return `You've registered to attend and vote at ${issuerName}'s meeting via telephone. You'll receive an email before the meeting date with all required information needed to attend the meeting. Have a great time!`;
    }
    return `You've registered to attend ${issuerName}'s meeting via telephone. You can join the meeting by dialing the number below on ${formatDate(
      { date: meetingDate, timezone: meetingTimezone }
    )} before the meeting starts. Please make sure to submit your online ballot here before the meeting, and have a great time!`;
  }

  if (meetingAttendance?.format === "virtual") {
    if (meetingAttendance?.legalProxy) {
      return `You've registered to attend and vote at ${issuerName}'s virtual meeting. You'll receive an email before the meeting date with all required information needed to attend the meeting. Have a great time!`;
    }
    return `You've registered to attend ${issuerName}'s virtual meeting. You can join the meeting by navigating to the URL below on ${formatDate(
      { date: meetingDate, timezone: meetingTimezone }
    )} before the meeting starts. Please make sure to submit your online ballot here before the meeting, and have a great time!`;
  }

  // We should never show this copy, but just in case we do, we'll have it here.
  return `You registered to attend ${issuerName}'s meeting. You will receive an email before the meeting date with all the materials needed to attend the meeting.`;
}
