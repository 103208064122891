import { useTheme } from "@mui/material";

export function EmptyGeneralSVG() {
  const theme = useTheme();
  return (
    <svg
      width="100%"
      height="360"
      viewBox="0 0 401 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="empty-general-svg"
    >
      <g clipPath="url(#clip0_808_34030)">
        <circle cx="155.5" cy="133" r="81" fill="#DEDEDE" />
        <rect x="0.5" y="247" width="400" height="60" rx="30" fill="white" />
        <path
          d="M78.5 196L119.203 265H37.7968L78.5 196Z"
          fill={theme.palette.l1.main}
        />
        <path
          d="M78.5 196L119.203 265H37.7968L78.5 196Z"
          fill="black"
          fillOpacity="0.5"
        />
        <path d="M306.5 167L364.524 266H248.476L306.5 167Z" fill="white" />
        <path
          d="M306.5 167L364.524 266H248.476L306.5 167Z"
          fill={theme.palette.l1.main}
          fillOpacity="0.5"
        />
        <path
          d="M184.5 113L279.763 275.75H89.2372L184.5 113Z"
          fill={theme.palette.l1.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_808_34030">
          <rect
            width="400"
            height="360"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
