import {
  ButtonV2,
  LoadingSkeleton,
  SayIcon,
  Text,
  Tooltip,
} from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { isDaysBeforeDate } from "src/pages/AttendMeeting/components/AttendMeetingForm/utils";
import { AttendMeetingDialog } from "src/pages/Communication/components/AttendMeetingDialog";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { ProxyCommunication } from "src/types";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { formatDate } from "src/utils/formatDate";
import { getMeetingIconName } from "./utils";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

export function MeetingInformation() {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });

  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const proxyCommunication = communication as ProxyCommunication;
  const isPrivateMeeting = proxyCommunication?.proxyEvent.meeting?.formats.some(
    (format) => format.format === "private"
  );
  const isPastDeadline = proxyCommunication?.proxyEvent?.meeting?.meetingDate
    ? !isDaysBeforeDate(proxyCommunication?.proxyEvent?.meeting?.meetingDate, 3)
    : false;

  const isLoading = isCommunicationLoading || isBrokerLoading;

  const onAttendMeetingClick = () => {
    track({
      name: TrackEventType.CommunicationDetailsRegisterToAttend,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    track({
      name: TrackEventType.CommunicationDetailsCancelRegisterToAttend,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    setIsOpen(false);
  };

  const handleOnFormatClick = (meetingFormat: string, value: string) => {
    if (meetingFormat === "virtual") {
      window.open(value, "_blank");
    }
  };

  if (!isLoading && proxyCommunication.proxyEvent.meeting === null) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        p: 5,
        borderRadius: "8px",
      }}
      data-testid="meeting-info"
    >
      <Text
        variant="subtitle2"
        emphasis="high"
        sx={{ mb: 6, color: theme.palette.l4.main }}
        loadingProps={{ sx: { width: "120px" } }}
      >
        {isLoading ? undefined : `Meeting information`}
      </Text>
      {isLoading ? (
        <Box sx={{ mb: 6 }}>
          <Box sx={{ display: "flex", my: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              mb: 4,
              color: theme.palette.l4.main,
              alignItems: "center",
            }}
          >
            <Box sx={{ height: "24px" }}>
              <SayIcon name="Calendar" color={theme.palette.l4.main} />
            </Box>
            <Text
              variant="body2"
              sx={{
                ml: 2,
              }}
            >
              {formatDate({
                date: proxyCommunication?.proxyEvent.meeting.meetingDate,
                timezone:
                  proxyCommunication?.proxyEvent.meeting.meetingTimezone,
              })}{" "}
              <br />
              {formatDate({
                date: proxyCommunication?.proxyEvent.meeting.meetingDate,
                format: "h:mm A",
                timezone:
                  proxyCommunication?.proxyEvent.meeting.meetingTimezone,
              })}{" "}
              {proxyCommunication?.proxyEvent.meeting.meetingTimezone}
            </Text>
          </Box>
          {proxyCommunication?.proxyEvent.meeting?.formats.map(
            (format, index) => {
              const { format: meetingFormat, value } = format;
              return (
                <Box
                  data-testid={`meeting-format-${index}`}
                  key={index}
                  sx={{
                    display: "flex",
                    py: 4,
                    color: theme.palette.l4.main,
                    borderTop: "1px solid #E0E0E0",
                    alignItems: "center",
                    "&:last-child": {
                      pb: communication?.meetingAttendance ? 0 : 4,
                    },
                  }}
                >
                  <Box sx={{ height: "24px" }}>
                    <SayIcon
                      name={getMeetingIconName(meetingFormat)}
                      color={theme.palette.l4.main}
                    />
                  </Box>
                  <Text
                    variant="body2"
                    sx={{
                      ml: 2,
                      cursor:
                        meetingFormat === "virtual" ? "pointer" : "default",
                      textUnderlineOffset: "4px",
                      wordBreak: "break-word",
                      "&:hover": {
                        textDecoration:
                          meetingFormat === "virtual" ? "underline" : "none",
                      },
                    }}
                    onClick={() => handleOnFormatClick(meetingFormat, value)}
                  >
                    {value}
                  </Text>
                </Box>
              );
            }
          )}
        </>
      )}
      {!communication?.meetingAttendance && (
        <Stack sx={{ mt: 2 }}>
          <Tooltip
            title={
              isPrivateMeeting
                ? "This meeting cannot be attended by shareholders."
                : ""
            }
          >
            <ButtonV2
              disabled={
                !communication ||
                !!communication?.meetingAttendance ||
                isPastDeadline ||
                isLoading ||
                isPrivateMeeting
              }
              onClick={onAttendMeetingClick}
              variant="secondary"
            >
              Register to attend
            </ButtonV2>
          </Tooltip>
        </Stack>
      )}
      <AttendMeetingDialog
        isOpen={isOpen}
        brokerId={brokerId}
        communicationId={communicationId}
        customerId={communication?.customer?.id}
        onClose={handleCloseDialog}
      />
    </Box>
  );
}
