import {
  ButtonV2,
  COLORS,
  LoadingSkeleton,
  ScrollingNav,
  Tabs,
} from "@asayinc/component-library";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { EventHeader } from "../Communication/components/EventHeader";
import { ShareholderResources } from "./components/ShareholderResources";
import { MeetingInformation } from "./components/MeetingInformation";
import { ProposalForm } from "./components/ProposalForm";
import { CorporateActionForm } from "./components/CorporateActionForm/CorporateActionForm";
import { LoadingProposalCards } from "./components/LoadingProposalCards";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { isProxyCommunication, getMenuSections } from "./utils";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

export function CommunicationDetails() {
  const theme = useTheme();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const [activeTab, setTab] = useState<"ballot" | "info">("ballot");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const isProxy = communication ? isProxyCommunication(communication) : true;
  const menuSections = communication ? getMenuSections(communication) : [];
  const isLoading = isCommunicationLoading || isBrokerLoading;
  const hideMobileTabs = !isProxy && communication?.documents.length === 0;

  const handleTabClick = (tab: string) => {
    track({
      name:
        tab === "ballot"
          ? TrackEventType.CommunicationDetailsBallotTab
          : TrackEventType.CommunicationDetailsInfoTab,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    setTab(tab as "ballot" | "info");
  };

  if (!isLoading && !communication) {
    return <Box>Error</Box>;
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            position: "sticky",
            top: "48px",
            zIndex: "500",
            borderBottom: `6px solid ${
              isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
            }`,
          },
        }}
      >
        {isLoading ? (
          <Box sx={{ display: "flex", mb: 3, mt: 3 }}>
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
          </Box>
        ) : hideMobileTabs ? null : (
          <Tabs
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.l4.main,
              },
            }}
            tabs={[
              {
                label: "Ballot",
                value: "ballot",
              },
              {
                label: "Info",
                value: "info",
              },
            ]}
            sx={{
              width: "100%",
              "& .MuiTab-root.Mui-selected": {
                color: theme.palette.l4.main,
              },
            }}
            activeTab={activeTab}
            clickHandler={handleTabClick}
          />
        )}
      </Stack>
      <Stack alignItems="center" mt={12} height="100%">
        <EventHeader sx={{ py: 8, px: 5 }} />
        <Stack
          direction="row"
          sx={{
            pt: 8,
            px: 10,
            mx: "auto",
            width: "100%",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              pt: 4,
              px: 4,
            },
          }}
        >
          <Box
            maxWidth="250px"
            minWidth="250px"
            mr={12}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          >
            {isLoading ? (
              <Box>
                <LoadingSkeleton
                  sx={{
                    my: 2.5,
                    ml: 4,
                  }}
                />
                <Box
                  sx={{
                    mt: 2,
                    pl: 4,
                    borderLeft: `2px solid ${COLORS.P_OUTLINE}`,
                  }}
                >
                  <LoadingSkeleton
                    sx={{
                      my: 2.5,
                      ml: 4,
                    }}
                  />
                  <LoadingSkeleton
                    sx={{
                      my: 2.5,
                      ml: 4,
                    }}
                  />
                  <LoadingSkeleton
                    sx={{
                      my: 2.5,
                      ml: 4,
                    }}
                  />
                </Box>
              </Box>
            ) : isProxy ? (
              <ScrollingNav
                sectionClassName="proposal"
                topPosition={80}
                offset={68}
                loading={isLoading}
                menuSections={menuSections}
                activeColor={theme.palette.l4.main}
              />
            ) : null}
          </Box>
          {isLoading ? (
            <LoadingProposalCards />
          ) : (isSmall && activeTab === "ballot") || !isSmall ? (
            isProxy ? (
              <ProposalForm />
            ) : (
              <CorporateActionForm />
            )
          ) : null}
          <Box
            maxWidth="240px"
            minWidth="240px"
            ml={12}
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: isSmall && activeTab === "info" ? "block" : "none",
                maxWidth: "unset",
                minWidth: "unset",
                width: "100%",
                ml: 0,
              },
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: "80px",
              }}
            >
              <ShareholderResources
                communication={communication}
                isLoading={isLoading}
                isProxyCommunication={isProxy}
              />
              {isProxy && <MeetingInformation />}
              <Stack
                sx={{
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: activeTab === "info" ? "grid" : "none",
                    position: "sticky",
                    bottom: 0,
                    right: 0,
                  },
                }}
              >
                <GradientBlock height="20px" />
                <Stack
                  sx={{
                    pb: 6,
                    display: activeTab === "info" ? "grid" : "none",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <ButtonV2 variant="primary" onClick={() => setTab("ballot")}>
                    {`<- Back to ${isProxy ? "ballot" : "election"}`}
                  </ButtonV2>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
