import { useTrackingParams } from "src/hooks/useTrackingParams";
import { useCommunicationQuery } from "../api";
import { ICommunicationParams } from "src/store/types";

export function useCommunicationWithTrackingQuery({
  brokerId,
  communicationId,
}: ICommunicationParams) {
  const trackingParams = useTrackingParams();
  return useCommunicationQuery({
    brokerId,
    communicationId,
    ...trackingParams,
  });
}
