import { Text, ButtonV2 } from "@asayinc/component-library";
import { Box, Dialog, DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

interface IAttendMeetingDialogProps {
  brokerId: string;
  communicationId: string;
  customerId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export function AttendMeetingDialog({
  brokerId,
  communicationId,
  customerId,
  isOpen,
  onClose,
}: IAttendMeetingDialogProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleContinueClick = () => {
    track({
      name: TrackEventType.CommunicationDetailsConfirmRegisterToAttend,
      brokerId,
      communicationId,
      customerId,
    });
    navigate(
      `/brokers/${brokerId}/communications/${communicationId}/attend-meeting` +
        location.search
    );
  };

  return (
    <Dialog
      data-testid="attend-meeting-dialog"
      disableScrollLock
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "340px", // Set your width here
          },
        },
      }}
    >
      <DialogContent sx={{ p: 8 }}>
        <Box sx={{ mb: 6 }}>
          <Text variant="subtitle1" sx={{ mb: 4 }}>
            Attend the Meeting
          </Text>
          <Text variant="body1">
            Are you sure you want to attend the meeting? Choosing to attend the
            meeting will navigate you away from your ballot.
          </Text>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box sx={{ display: "grid", width: "100%" }}>
            <ButtonV2
              data-testid="continue-button"
              variant="primary"
              sx={{ mb: 3 }}
              onClick={handleContinueClick}
              size="small"
            >
              Continue
            </ButtonV2>
          </Box>
          <Box sx={{ display: "grid", width: "100%" }}>
            <ButtonV2
              variant="secondary"
              onClick={onClose}
              size="small"
              data-testid="cancel-button"
            >
              Cancel
            </ButtonV2>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
