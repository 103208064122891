import { Box, SxProps } from "@mui/material";

interface IGradientBlockProps {
  height: string;
  sx?: SxProps;
}

export function GradientBlock({ height, sx }: IGradientBlockProps) {
  return (
    <Box
      data-testid="gradient-block"
      sx={{
        background:
          "linear-gradient(0deg, #F0F0F0 40%, rgba(240, 240, 240, 0) 100%)",
        height,
        ...sx,
      }}
    />
  );
}
