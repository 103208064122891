import { CorporateActionCommunication } from "src/types";
import { ElectionChoice as ElectionChoiceType } from "src/types/Communication";

export function getLabel(
  choice: ElectionChoiceType,
  communication: CorporateActionCommunication
) {
  const { corporateActionEvent, offer, security } = communication;
  const { issuerName } = security;
  const { type } = corporateActionEvent;
  const { conditions } = offer;

  switch (choice) {
    case "yes":
      switch (type) {
        case "buyback":
        case "buyback_auction":
          return `Yes, I would like to sell shares back to ${issuerName}`;
        case "exchange_offer":
          return `Yes, I would like to exchange my shares`;
        case "offer_to_purchase":
          return `Yes, I would like to sell shares to ${issuerName}`;
        case "offer_to_purchase_auction":
          return `Yes, I would like to sell shares to ${conditions.offererParty}`;
        case "odd_lot_offer":
          return "Yes, I would like to sell my shares";
        case "rights_offer":
          return `Yes, I would like to exchange my rights with ${conditions.offererParty}`;
        case "rights_offer_oversubscription":
          return `Yes, I would like to exchange my rights with ${conditions.offererParty}`;
        default:
          return "";
      }
    case "no":
      return "No, I'm not interested";
    case "purchase":
      return "Yes, I would like to purchase shares for a full lot";
    case "sell":
      return "Yes, I would like to sell shares to exit my lot";
    case "cash":
      // Not sure if we support dividend_option
      //   if (type === "dividend_option") {
      //     return "Cash payment";
      //   }
      return "Cash";
    case "stock":
      // Not sure if we support dividend_option
      //   if (type === "dividend_option") {
      //     return "Stock payment";
      //   }
      return "Stock";
    case "mixed":
      // Not sure if we support dividend_option
      //   if (type === "dividend_option") {
      //     return "Mixed payment";
      //   }
      return "Mixed";
  }
}
