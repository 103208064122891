import { Broker } from "src/types";
import { warrenGApi } from "../rootApi";

export const brokerApi = warrenGApi.injectEndpoints({
  endpoints: (builder) => ({
    broker: builder.query<Broker, string>({
      query: (brokerId: string) => ({
        url: `${brokerId}/`,
      }),
      providesTags: ["Broker"],
    }),
  }),
});

export const { useBrokerQuery } = brokerApi;
