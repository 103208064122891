import {
  COLORS,
  IconButton,
  LoadingSkeleton,
  Tabs,
  Text,
  Tooltip,
} from "@asayinc/component-library";
import { NotificationsOutlined } from "@mui/icons-material";
import { Box, Stack, useTheme } from "@mui/material";

import { Communication } from "src/types";
import { getUniqueAccountNumbersFromCommunications } from "../../utils";
import { useParams } from "react-router-dom";
import { useBrokerQuery } from "src/store/warrenG/broker";
import {
  useCustomerPreferencesQuery,
  useCustomerQuery,
} from "src/store/warrenG/customer";
import { useState } from "react";
import { CustomerSubscriptionDialog } from "../CustomerSubscriptionDialog";

interface ICommunicationsHeaderProps {
  activeTab: string;
  communications: Communication[];
  isCommunicationsLoading: boolean;
  handleTabClick: (value: string) => void;
}

export function CommunicationsHeader({
  activeTab,
  communications = [],
  isCommunicationsLoading,
  handleTabClick,
}: ICommunicationsHeaderProps) {
  const { brokerId, customerId } = useParams() as {
    brokerId: string;
    customerId: string;
  };
  const { isLoading: isBrokerLoading, data: broker } = useBrokerQuery(brokerId);
  const { isLoading: isCustomerLoading, data: customer } = useCustomerQuery({
    brokerId,
    customerId,
  });
  // We want to load the customer preferences here so we avoid showing the
  // loading state when the customer opens the dialog
  const { isLoading: isCustomerPreferencesLoading } =
    useCustomerPreferencesQuery({
      brokerId,
      customerId,
    });
  const theme = useTheme();
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  const handleOpenSubscriptionDialog = () => {
    setIsSubscriptionDialogOpen(true);
  };

  const handleCloseSubscriptionDialog = () => {
    setIsSubscriptionDialogOpen(false);
  };

  const accountNumbers =
    getUniqueAccountNumbersFromCommunications(communications);
  const [firstAccountNumber, ...rest] = accountNumbers;
  const isLoading =
    isCommunicationsLoading ||
    isBrokerLoading ||
    isCustomerLoading ||
    isCustomerPreferencesLoading;

  return (
    <Stack
      alignItems="center"
      sx={{
        borderTop: `6px solid ${
          isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
        }`,
        marginTop: 8,
        borderRadius: "0px 0px 8px 8px",
        backgroundColor: "#FFFFFF",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          marginTop: 4,
        },
      }}
    >
      <Box sx={{ position: "absolute", top: 20, right: 20 }}>
        {isLoading ? (
          <LoadingSkeleton sx={{ width: "20px" }} />
        ) : (
          <IconButton
            onClick={handleOpenSubscriptionDialog}
            sx={{
              ":active": {
                backgroundColor: theme.palette.l5.main,
              },
              "& .MuiSvgIcon-root:active": {
                fill: theme.palette.l1.main,
              },
            }}
          >
            <NotificationsOutlined />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 6,
          width: "120px",
        }}
      >
        {isLoading ? (
          <LoadingSkeleton sx={{ width: "120px" }} />
        ) : (
          <img
            src={broker?.theme.logo}
            style={{ height: broker?.theme.logoHeight }}
            alt="Broker Logo"
          />
        )}
      </Box>
      <Stack
        sx={{
          mb: 6,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          variant="h5"
          sx={{ textAlign: "center" }}
          loadingProps={{
            sx: {
              width: "280px",
            },
          }}
        >
          {isLoading ? undefined : customer?.name || ""}
        </Text>
        {isLoading ? (
          <LoadingSkeleton
            sx={{ display: "table", margin: "0 auto", width: "240px", mt: 2 }}
          />
        ) : (
          <>
            {accountNumbers.length > 0 ? (
              <Box sx={{ display: "flex", mt: 2 }}>
                <Text variant="body2" sx={{ textAlign: "center" }}>
                  {`Account${
                    accountNumbers.length > 1 ? "s" : ""
                  }: ${firstAccountNumber}`}
                </Text>
                {accountNumbers.length > 1 && (
                  <Tooltip
                    title={rest.join(", ")}
                    sx={{ ml: 1, cursor: "default" }}
                    placement="top"
                  >
                    <Text variant="body2">{`and ${
                      accountNumbers.length - 1 + " more"
                    }`}</Text>
                  </Tooltip>
                )}
              </Box>
            ) : null}
          </>
        )}
      </Stack>
      <Box sx={{ width: "100%", px: 5 }}>
        {isLoading ? (
          <Box sx={{ display: "flex", mb: 3 }}>
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
          </Box>
        ) : (
          <Tabs
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.l4.main,
              },
            }}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: theme.palette.l4.main,
              },
            }}
            tabs={[
              {
                label: "Open events",
                value: "open",
              },
              {
                label: "Past events",
                value: "past",
              },
            ]}
            activeTab={activeTab}
            clickHandler={handleTabClick}
          />
        )}
      </Box>
      <CustomerSubscriptionDialog
        isOpen={isSubscriptionDialogOpen}
        onClose={handleCloseSubscriptionDialog}
      />
    </Stack>
  );
}
