import { TrackEventType } from "src/analytics/types";
import {
  Communication,
  CorporateActionCommunication,
  ProxyCommunication,
} from "src/types";
import { isPassedCutoffDate } from "../../utils";

export function getTrackEvent(communication: Communication): TrackEventType {
  const { type } = communication;
  if (isPassedCutoffDate(communication)) {
    return TrackEventType.AllBallotsCheckResults;
  }

  if (type === "corporate_action_election") {
    const { electionResults } = communication as CorporateActionCommunication;
    if (electionResults.length > 0) {
      return TrackEventType.AllBallotsUpdate;
    }
  }

  const { voteResults } = communication as ProxyCommunication;
  if (voteResults.length > 0) {
    return TrackEventType.AllBallotsUpdate;
  }

  return TrackEventType.AllBallotsVote;
}
