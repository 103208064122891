import { ButtonV2, LoadingSkeleton } from "@asayinc/component-library";
import { Stack } from "@mui/material";

export function LoadingProposalCards() {
  return (
    <Stack sx={{ maxWidth: "1000px", flexGrow: 1 }}>
      <Stack
        sx={{
          mb: 8,
          p: 6,
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        }}
      >
        <LoadingSkeleton sx={{ mb: 3, py: 2, width: "40%" }} />
        <LoadingSkeleton sx={{ mb: 6 }} />
        <LoadingSkeleton sx={{ py: 4 }} />
      </Stack>
      <Stack
        sx={{ mb: 8, p: 6, backgroundColor: "#FFFFFF", borderRadius: "8px" }}
      >
        <LoadingSkeleton sx={{ mb: 3, py: 2, width: "40%" }} />
        <LoadingSkeleton sx={{ mb: 6 }} />
        <LoadingSkeleton sx={{ py: 4 }} />
      </Stack>
      <Stack
        sx={{ mb: 8, p: 6, backgroundColor: "#FFFFFF", borderRadius: "8px" }}
      >
        <LoadingSkeleton sx={{ mb: 3, py: 2, width: "40%" }} />
        <LoadingSkeleton sx={{ mb: 6 }} />
        <LoadingSkeleton sx={{ py: 4 }} />
      </Stack>
      <ButtonV2 disabled>Submit</ButtonV2>
    </Stack>
  );
}
