import { ButtonV2, Text } from "@asayinc/component-library";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

export function LoadingCommunicationRow() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack
      direction="row"
      sx={{
        p: "28px 20px 24px 20px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        mt: 8,
        [theme.breakpoints.down("sm")]: {
          mt: 4,
        },
      }}
    >
      <Box sx={{ mr: 8 }}>
        <Text
          variant="subtitle2"
          sx={{ pb: 2 }}
          loadingProps={{ sx: { width: "160px" } }}
        >
          {undefined}
        </Text>
        <Text
          variant="subtitle1"
          sx={{ pb: 2, pr: 2 }}
          loadingProps={{ sx: { width: "240px" } }}
        >
          {undefined}
        </Text>
        <Text variant="body3" loadingProps={{ sx: { width: "160px" } }}>
          {undefined}
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexGrow: 1,
          minWidth: isSmall ? "120px" : "180px",
        }}
      >
        <ButtonV2
          data-testid="primary-cta"
          size={isSmall ? "small" : "medium"}
          disabled={true}
        />
      </Box>
    </Stack>
  );
}
