import { COLORS, SayIcon, Text } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";

import { useBrokerQuery } from "src/store/warrenG/broker";
import { LoadingConfirmationDialog } from "src/components/molecules/LoadingConfirmationDialog";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

export function ElectionConfirmation() {
  const theme = useTheme();
  const { brokerId, communicationId } = useParams() as {
    brokerId: string;
    communicationId: string;
  };
  const { isLoading } = useBrokerQuery(brokerId);
  const location = useLocation();
  const backLink = location?.state?.backLink;
  const previousIssuerName = location?.state?.previousIssuerName;

  const handleEditClick = () => {
    track({
      name: TrackEventType.ElectionConfirmationEdit,
      brokerId,
      communicationId,
      customerId: location?.state?.customerId,
    });
  };

  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      {isLoading ? (
        <LoadingConfirmationDialog />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px 32px",
            backgroundColor: "#FFFFFF",
            borderRadius: "8px",
            maxWidth: "320px",
            textAlign: "center",
            mx: 5,
          }}
        >
          <SayIcon
            name="ProposalGeneralOutline"
            size="48px"
            color={theme.palette.l4.main}
          />
          <Text
            variant="h5"
            sx={{ mt: 8, color: theme.palette.l4.main }}
            data-testid="Title-success"
          >
            Election Submitted!
          </Text>
          <Text sx={{ mt: 6 }} variant="body2" data-testid="description">
            {previousIssuerName} appreciates your election. You will receive an
            email confirmation shortly.
          </Text>
          <Text variant="body3" emphasis="medium" sx={{ mt: 8 }}>
            Changed your mind? <br /> Edit your election{" "}
            <Link to={backLink} data-testid="change-ballot-link">
              <strong
                style={{ color: COLORS.P_MED_EMPHASIS }}
                onClick={handleEditClick}
              >
                here.
              </strong>
            </Link>
          </Text>
        </Box>
      )}
    </Stack>
  );
}
