import { Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";

import { StandardInputField } from "src/components/atoms/StandardInputField";
import { Position } from "src/types/Communication";

interface IGeneralChoiceDetailsProps {
  index: number;
  isExchange?: boolean;
  hasSingleAccount: boolean;
  position: Position;
}

export function GeneralChoiceDetails({
  index,
  isExchange = false,
  hasSingleAccount,
  position,
}: IGeneralChoiceDetailsProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          alignItems: "start",
        },
      }}
    >
      <Text variant="body2" sx={{ mr: 1 }}>
        Account {hasSingleAccount ? "" : index + 1}: ****
        {position.accountIdentifier.replaceAll("*", "")} (total{" "}
        {position.sharesOwned} shares)
      </Text>
      <Box sx={{ display: "flex" }}>
        <Text variant="body2" sx={{ mr: 2 }}>
          {isExchange ? "exchange" : "sell"}
        </Text>
        <StandardInputField
          autoFocusOnClick={true}
          name={`choiceDetails.${index}.sharesElected`}
          placeholder={`e.g., ${position.sharesOwned}`}
          rules={{
            required: {
              value: true,
              message: "Number of shares is required",
            },
            max: {
              value: position.sharesOwned,
              message: "Cannot submit more shares than you own",
            },
            min: {
              value: 1,
              message: "You must elect at least one share",
            },
            validate: {
              isWholeNumber: (value) => {
                if (value % 1 !== 0) {
                  return "Number of shares must be a whole number";
                }
                return true;
              },
            },
          }}
        />
        <Text variant="body2" sx={{ ml: 2 }}>
          shares
        </Text>
      </Box>
    </Box>
  );
}
