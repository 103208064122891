import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { CorporateActionCommunication } from "src/types";
import { GeneralChoiceDetails } from "./GeneralChoiceDetails";
import { RightsOfferChoiceDetails } from "./RightsOfferChoiceDetails";
import { AuctionChoiceDetails } from "./AuctionChoiceDetails";

interface IChoiceDetailProps {
  communication: CorporateActionCommunication;
}

export const ChoiceDetails = ({ communication }: IChoiceDetailProps) => {
  const { offer, positions, corporateActionEvent } = communication;
  const { watch } = useFormContext();

  const choice = watch("choice");
  const { type } = corporateActionEvent;
  const { id, conditions } = offer;
  const hasSingleAccount = positions.length === 1;

  return (
    <Box sx={{ ml: 9.5, borderTop: "1px solid #E0E0E0" }}>
      {positions.map((position, index) => {
        return (
          <Box key={`${id}-${index}`} sx={{ py: 2.5, pl: 3, pr: 3 }}>
            {type === "buyback" && choice === "yes" && (
              <GeneralChoiceDetails
                hasSingleAccount={hasSingleAccount}
                position={position}
                index={index}
              />
            )}
            {type === "buyback_auction" && choice === "yes" && (
              <AuctionChoiceDetails
                position={position}
                index={index}
                isSinglePosition={hasSingleAccount}
                conditions={conditions}
              />
            )}
            {type === "exchange_offer" && choice === "yes" && (
              <GeneralChoiceDetails
                hasSingleAccount={hasSingleAccount}
                position={position}
                index={index}
              />
            )}
            {type === "merger_consideration_election" &&
              (choice === "cash" ||
                choice === "stock" ||
                choice === "mixed") && (
                <GeneralChoiceDetails
                  hasSingleAccount={hasSingleAccount}
                  position={position}
                  index={index}
                  isExchange={true}
                />
              )}
            {type === "offer_to_purchase" && choice === "yes" && (
              <GeneralChoiceDetails
                hasSingleAccount={hasSingleAccount}
                position={position}
                index={index}
              />
            )}
            {type === "offer_to_purchase_auction" && choice === "yes" && (
              <AuctionChoiceDetails
                position={position}
                index={index}
                isSinglePosition={positions.length === 1}
                conditions={conditions}
              />
            )}
            {type === "rights_offer" && choice === "yes" && (
              <RightsOfferChoiceDetails
                hasSingleAccount={hasSingleAccount}
                position={position}
                index={index}
              />
            )}
            {type === "rights_offer_oversubscription" && choice === "yes" && (
              <RightsOfferChoiceDetails
                position={position}
                hasSingleAccount={hasSingleAccount}
                index={index}
                showOversubscription={true}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
