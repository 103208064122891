import { Outlet } from "react-router-dom";
import { AppContainer } from "./components/containers/AppContainer/AppContainer";

export function App() {
  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
}
