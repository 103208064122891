import { ButtonV2, ChipV2, Text } from "@asayinc/component-library";
import { Box, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getSubmitByDate,
  getTitle,
  getCtaText,
  getButtonType,
  isPassedCutoffDate,
  hasVotedAlready,
} from "../../utils";
import { Communication } from "src/types";
import { isCommunicationViewed } from "src/pages/Communication/utils";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { getTrackEvent } from "./utils";
import {
  getContestingParty,
  isContestedMeeting,
} from "src/pages/Communication/components/EventHeader/utils";

interface ICommunicationRowProps {
  brokerId: string;
  communication: Communication;
  communicationIndex: number;
  isNextCommunication?: boolean;
  sx?: SxProps;
}

export function CommunicationRow({
  brokerId,
  communication,
  communicationIndex,
  isNextCommunication,
  sx,
}: ICommunicationRowProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { security } = communication;
  const { issuerName, ticker } = security;

  const handleCommunicationClick = () => {
    track({
      name: isNextCommunication
        ? TrackEventType.AllBallotsVoteNext
        : getTrackEvent(communication),
      nextCommunicationId: communication.id,
      communicationIndex: communicationIndex,
      brokerId,
      communicationId: communication?.id,
      customerId: communication?.customer.id,
    });

    // Look at session storage to see if this communication
    // was the initial communication the user landed on. If so
    // add back the tracking variables to the search params when
    // user navigates to the ballot page.

    // Use sessionStorage here so that the params are only added
    // within the same tab. If the user opens a new tab, the params
    // will not be added.
    const params = sessionStorage.getItem(communication.id);
    navigate(
      `/brokers/${brokerId}/communications/${communication.id}/${
        isPassedCutoffDate(communication) ||
        hasVotedAlready(communication) ||
        isCommunicationViewed(communication)
          ? "details"
          : ""
      }` + (params ? params : location.search || "")
    );
  };

  return (
    <Stack
      direction={isSmall ? "column" : "row"}
      sx={{
        p: "28px 20px 24px 20px",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        ...sx,
      }}
      data-testid="communication-info"
    >
      <Box sx={{ mr: 8 }}>
        <Text variant="subtitle2" sx={{ pb: 2 }}>
          {issuerName} ({ticker})
        </Text>
        <Stack direction="row" alignItems="center" sx={{ pb: 2 }}>
          <Text variant="subtitle1" sx={{ pr: 2 }}>
            {getTitle(communication)}
          </Text>
          {communication && isContestedMeeting(communication) && (
            <ChipV2
              label="Contested"
              size="small"
              sx={{
                "& .MuiChip-deleteIcon": {
                  color: theme.palette.l1.main,
                },
              }}
              variant="secondary"
              tooltip={`Contested by ${getContestingParty(communication)}`}
            />
          )}
        </Stack>
        <Text variant="body3">
          Submit by {getSubmitByDate(communication) || "-"}
        </Text>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexGrow: 1,
          minWidth: "180px",
          [theme.breakpoints.down("sm")]: {
            mt: 4,
            justifyContent: "flex-start",
            minWidth: "120px",
          },
        }}
      >
        <ButtonV2
          onClick={handleCommunicationClick}
          size={isSmall ? "small" : "medium"}
          variant={getButtonType(communication)}
          data-testid="communication-row-primary-cta"
        >
          {getCtaText(communication)}
        </ButtonV2>
      </Box>
    </Stack>
  );
}
