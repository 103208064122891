import "./styles/reset.css";
import "./styles/index.css";

import { SegmentAnalytics } from "@segment/analytics.js-core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { store } from "./store";
import { router } from "./router";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    config: {
      REACT_APP_WARRENG_URL: string;
      REACT_APP_CONSUMER_URL: string;
      REACT_APP_ASSETS: string;
      REACT_APP_SEGMENT_API_URL_EXTENSION: string;
      ADOBE_EMBED: string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AdobeDC: any;
  }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
