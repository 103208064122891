import { COLORS, Text } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CustomerSubscriptionForm } from "../CustomerSubscriptionForm";

interface ICustomerSubscriptionDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CustomerSubscriptionDialog({
  isOpen,
  onClose,
}: ICustomerSubscriptionDialogProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const content = (
    <Stack
      sx={{ overflow: "hidden" }}
      data-testid="customer-subscription-dialog-content"
    >
      <Box
        sx={{
          py: 5,
          borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
        }}
      >
        <Text
          variant={isSmall ? "subtitle1" : "h5"}
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          Subscription settings
        </Text>
        <IconButton
          sx={{ position: "absolute", top: "11px", right: "8px" }}
          onClick={onClose}
        >
          <Close sx={{ color: COLORS.BLACK }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ p: 6, display: "flex", flexDirection: "column" }}>
        <CustomerSubscriptionForm onSubmit={onClose} />
      </DialogContent>
    </Stack>
  );

  if (isSmall) {
    return (
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { height: "100%", bottom: "-24px", pb: 6 },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Dialog
      data-testid="customer-subscription-dialog"
      fullWidth
      open={isOpen}
      onClose={onClose}
      disableScrollLock
      PaperProps={{ sx: { width: "100%", m: 0 } }}
      sx={{
        mx: "auto",
        px: 6,
        maxWidth: "721px",
      }}
    >
      {content}
    </Dialog>
  );
}
