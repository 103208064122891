import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseQueryWithCamelize } from "src/store/utils";

export const consumerApi = createApi({
  baseQuery: getBaseQueryWithCamelize(
    fetchBaseQuery({
      baseUrl: `${window.config.REACT_APP_CONSUMER_URL}/v3/`,
    })
  ),
  reducerPath: "consumerApi",
  tagTypes: [],
  endpoints: () => ({}),
});
