import { LoadingSkeleton } from "@asayinc/component-library";
import { Box } from "@mui/material";

export function LoadingConfirmationDialog() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px 32px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        maxWidth: "320px",
        textAlign: "center",
        mx: 5,
      }}
    >
      <LoadingSkeleton sx={{ width: "20px" }} />
      <LoadingSkeleton sx={{ width: "200px", mt: 8 }} />
      <LoadingSkeleton sx={{ mt: 6 }} />
      <LoadingSkeleton sx={{ mt: 2 }} />
      <LoadingSkeleton sx={{ width: "100px", mt: 8 }} />
      <LoadingSkeleton sx={{ width: "100px", mt: 2 }} />
    </Box>
  );
}
