import { COLORS } from "@asayinc/component-library";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import {
  CorporateActionCommunication,
  ElectionChoice as ElectionChoiceType,
} from "src/types/Communication";
import { isPassedCutoffDate } from "src/pages/Communications/utils";
import { ChoiceDetails } from "../ChoiceDetails";
import { ElectionChoice } from "../ElectionChoice/ElectionChoice";
import { ChoiceErrors } from "../ChoiceErrors";

interface IElectionChoicesProps {
  communication: CorporateActionCommunication;
  electionChoices: ElectionChoiceType[];
}

export function ElectionChoices({
  communication,
  electionChoices,
}: IElectionChoicesProps) {
  const theme = useTheme();
  const isPastCutoff = isPassedCutoffDate(communication);
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext();
  const {
    corporateActionEvent: { type },
  } = communication;
  const hasErrors = errors && Object.keys(errors).length > 0;

  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name="choice"
      render={({ field }) => {
        const { value } = field;
        return (
          <RadioGroup
            {...field}
            onChange={(e) => {
              field.onChange(e);
              resetField("choiceDetails");
            }}
          >
            {electionChoices.map((choice, index) => {
              const isSelected = choice === value;
              // Since we now have inline input fields for when a user only has a single position
              // we will only show the choice details if
              // - The user has multiple positions
              // - Communication is a buyback auction or offer to purchase auction
              //   Auctions give users the additional option of inputting a bid amount
              // - "yes" choice is selected

              const showChoiceDetails =
                isSelected &&
                value !== "no" &&
                (communication.positions.length > 1 ||
                  type === "buyback_auction" ||
                  type === "offer_to_purchase_auction" ||
                  type === "merger_consideration_election");
              return (
                <Stack
                  key={`electionchoice-${index}`}
                  sx={{
                    mb: 3,
                    "&:last-child": {
                      mb: 0,
                    },
                  }}
                >
                  <Stack
                    sx={{
                      border: isSelected
                        ? `1px solid ${
                            hasErrors
                              ? COLORS.ERROR_DARK
                              : theme.palette.l4.main
                          }`
                        : `1px solid ${COLORS.P_OUTLINE}`,
                      borderRadius: "8px",
                      mx: 0,
                      cursor: isPastCutoff ? "auto" : "pointer",
                    }}
                  >
                    <FormControlLabel
                      sx={{ m: 0, p: 3 }}
                      disabled={isPastCutoff}
                      onMouseDown={(e) => {
                        if (!isSelected) {
                          e.preventDefault();
                        }
                      }}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: COLORS.P_OUTLINE,
                            "&, &.Mui-checked": {
                              color: isSelected
                                ? hasErrors
                                  ? COLORS.ERROR_DARK
                                  : theme.palette.l4.main
                                : null,
                            },
                            p: 0,
                            mr: 3,
                          }}
                        />
                      }
                      value={choice}
                      label={
                        <ElectionChoice
                          choice={choice}
                          communication={communication}
                          hasErrors={hasErrors}
                          onClick={() => {
                            field.onChange(choice);
                          }}
                        />
                      }
                    />
                    {showChoiceDetails && (
                      <ChoiceDetails communication={communication} />
                    )}
                  </Stack>
                  {hasErrors && isSelected && <ChoiceErrors />}
                </Stack>
              );
            })}
          </RadioGroup>
        );
      }}
    />
  );
}
