import { COLORS, Text, nFormatter } from "@asayinc/component-library";
import { Box } from "@mui/material";

import { StandardInputField } from "src/components/atoms/StandardInputField";
import { Condition, Position } from "src/types/Communication";

interface IAuctionChoiceDetailsProps {
  position: Position;
  index: number;
  isSinglePosition: boolean;
  conditions: Condition;
}

export function AuctionChoiceDetails({
  position,
  index,
  conditions,
  isSinglePosition,
}: IAuctionChoiceDetailsProps) {
  const { minimumBidPrice, maximumBidPrice, bidIncrement } = conditions;

  if (isSinglePosition) {
    return (
      <>
        <Box>
          <Text variant="body2" display="inline" sx={{ mr: 2 }}>
            If you wish to specify a bid for your election, please enter your
            bid here:{" "}
          </Text>
          <StandardInputField
            placeholder={`e.g., ${position.sharesOwned}`}
            name="choiceDetails.0.shareholderBid"
            rules={{
              max: {
                value: maximumBidPrice || "",
                message: `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`,
              },
              min: {
                value: minimumBidPrice || "",
                message: `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`,
              },
              validate: {
                increment: (value) => {
                  if (value > 0 && bidIncrement) {
                    // Multiplying by 100 to avoid floating point errors
                    // https://stackoverflow.com/questions/3966484/why-does-modulus-operator-return-fractional-number-in-javascript
                    // Assuming smallest bidIncrement will be .01 (1 cent) since we are dealing with money so multiply by 100 should
                    // always reutrn a whole number
                    // Adding .toFixed(0) to convert to string and remove decimal places
                    // Ex: 8.3*100 should be 830, but we get 830.0000000000001
                    const intValue = (parseFloat(value) * 100).toFixed(0);
                    const intIncrement = (
                      parseFloat(bidIncrement) * 100
                    ).toFixed(0);
                    return (
                      parseInt(intValue) % parseInt(intIncrement) === 0 ||
                      `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`
                    );
                  }
                  return true;
                },
              },
            }}
          />
        </Box>
        <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS, mt: 2 }}>
          Bid must be between{" "}
          {minimumBidPrice ? nFormatter(parseFloat(minimumBidPrice)) : ""} and{" "}
          {maximumBidPrice ? nFormatter(parseFloat(maximumBidPrice)) : ""}
          {bidIncrement &&
            ` in increments of ${nFormatter(parseFloat(bidIncrement))}`}
        </Text>
      </>
    );
  }

  return (
    <>
      <Box>
        <Text variant="body2" display="inline">
          Account {index + 1}: ****
          {position.accountIdentifier.replaceAll("*", "")} (total{" "}
          {position.sharesOwned} shares) shares elected:
        </Text>
        <StandardInputField
          placeholder={`e.g., ${position.sharesOwned}`}
          name={`choiceDetails.${index}.sharesElected`}
          rules={{
            required: true,
            max: {
              value: position.sharesOwned,
              message: "Cannot submit more shares than you own",
            },
            min: {
              value: 1,
              message: "You must elect at least one share",
            },
            validate: {
              isWholeNumber: (value) => {
                if (value % 1 !== 0) {
                  return "Number of shares must be a whole number";
                }
                return true;
              },
            },
          }}
        />
      </Box>
      <Box>
        <Text variant="body2">
          If you wish to specify a bid for your election, please enter your bid
          here:{" "}
        </Text>
        <StandardInputField
          placeholder={`e.g., ${position.sharesOwned}`}
          name={`choiceDetails.${index}.shareholderBid`}
          rules={{
            max: {
              value: maximumBidPrice || "",
              message: `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`,
            },
            min: {
              value: minimumBidPrice || "",
              message: `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`,
            },
            validate: {
              increment: (value) => {
                if (bidIncrement) {
                  return (
                    parseFloat(value) % parseFloat(bidIncrement) === 0 ||
                    `Bid must be between ${minimumBidPrice} and ${maximumBidPrice} in increments of ${bidIncrement}`
                  );
                }
                return true;
              },
            },
          }}
        />
      </Box>
      <Text variant="body3" sx={{ color: COLORS.P_MED_EMPHASIS, mt: 2 }}>
        Bid must be between{" "}
        {minimumBidPrice ? nFormatter(parseFloat(minimumBidPrice)) : ""} and{" "}
        {maximumBidPrice ? nFormatter(parseFloat(maximumBidPrice)) : ""}
        {bidIncrement &&
          ` in increments of ${nFormatter(parseFloat(bidIncrement))}`}
      </Text>
    </>
  );
}
