import { configureStore } from "@reduxjs/toolkit";
import { warrenGApi } from "./warrenG";
import { consumerApi } from "./consumer";

export const store = configureStore({
  reducer: {
    [warrenGApi.reducerPath]: warrenGApi.reducer,
    [consumerApi.reducerPath]: consumerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(warrenGApi.middleware)
      .concat(consumerApi.middleware),
});
