import { Text } from "@asayinc/component-library";
import { Box, capitalize } from "@mui/material";

import { CustomFormRadioGroup } from "src/components/molecules/CustomFormRadioGroup";
import { Proposal } from "src/types/Communication";

interface IProposalCardProps {
  isAttendingMeeting: boolean;
  isPassedCutoffDate: boolean;
  letterIndex?: number;
  proposal: Proposal;
}

export function ProposalCard({
  isAttendingMeeting,
  isPassedCutoffDate,
  letterIndex,
  proposal,
}: IProposalCardProps) {
  const { id, details, recommendation, voteChoices } = proposal;
  return (
    <Box
      sx={{
        mt: 6,
        borderBottom: "1px solid #E0E0E0",
        pb: 6,
        "&:last-child": { borderBottom: "none", pb: 0 },
      }}
      id={`proposal-${id}`}
    >
      <Text sx={{ mb: 6 }} variant="body2">
        {letterIndex !== undefined
          ? `${String.fromCharCode(65 + letterIndex)}. `
          : ""}
        {details}
      </Text>
      <CustomFormRadioGroup
        name={id}
        isDisabled={isPassedCutoffDate || isAttendingMeeting}
        isRequired={true}
        options={voteChoices.map((choice) => ({
          label: capitalize(choice).replaceAll("_", " "),
          value: choice,
          disabled: isPassedCutoffDate || isAttendingMeeting,
          isRecommended: choice === recommendation,
        }))}
      />
    </Box>
  );
}
