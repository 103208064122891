import { LoadingSkeleton, SayIcon, Text } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { Card } from "src/pages/AttendMeeting/components/Card";
import { getMeetingIconName } from "src/pages/CommunicationDetails/components/MeetingInformation/utils";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { ProxyCommunication } from "src/types";
import { formatDate } from "src/utils/formatDate";

export function AttendMeetingDetailsCard() {
  const { brokerId, communicationId } = useParams() as {
    brokerId: string;
    communicationId: string;
  };
  const { isLoading, data: communication } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });
  const theme = useTheme();

  if (isLoading) {
    return (
      <Card sx={{ mb: 6, width: "100%" }}>
        <Box sx={{ display: "flex", my: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
      </Card>
    );
  }

  const {
    meetingAttendance,
    proxyEvent: { meeting },
  } = communication as ProxyCommunication;
  const { formats, meetingDate, meetingTimezone } = meeting;
  const selectedFormat = formats.find(
    (format) => format.format === meetingAttendance?.format
  );

  const handleOnFormatClick = () => {
    if (selectedFormat) {
      if (selectedFormat.format === "virtual") {
        window.open(selectedFormat.value, "_blank");
      }
    }
  };

  return (
    <Card
      title="Meeting Details"
      sx={{
        mt: 8,
        mb: 5,
        [theme.breakpoints.down("sm")]: {
          mt: 4,
          mb: 2,
        },
      }}
    >
      <Stack
        sx={{
          color: theme.palette.l4.main,
          flexGrow: 1,
          borderBottom: "1px solid #E0E0E0",
          pb: 4,
          alignItems: "center",
        }}
        direction="row"
      >
        <Box>
          <SayIcon name="Calendar" color={theme.palette.l4.main} size="24px" />
        </Box>
        <Text
          data-testid="section-meeting-info_1"
          sx={{
            ml: 3,
          }}
          variant="body2"
        >
          {`${formatDate({
            date: meetingDate,
            timezone: meetingTimezone,
          })} at ${formatDate({
            date: meetingDate,
            format: "h:mm A",
            timezone: meetingTimezone,
          })} ${meetingTimezone}`}
        </Text>
      </Stack>
      <Box
        data-testid="section-meeting-info_2"
        sx={{
          display: "flex",
          color: theme.palette.l4.main,
          alignItems: "center",
          borderBottom: "1px solid #E0E0E0",
          "&:last-child": {
            borderBottom: "none",
            pb: 0,
          },
          py: 4,
        }}
      >
        <Box>
          <SayIcon
            name={getMeetingIconName(selectedFormat?.format || "")}
            color={theme.palette.l4.main}
          />
        </Box>
        <Box>
          <Text
            variant="body2"
            sx={{
              ml: 3,
              cursor:
                selectedFormat?.format === "virtual" ? "pointer" : "default",
              textUnderlineOffset: "4px",
              wordBreak: "break-word",
              "&:hover": {
                textDecoration:
                  selectedFormat?.format === "virtual" ? "underline" : "none",
              },
            }}
            onClick={handleOnFormatClick}
          >
            {selectedFormat?.value}
          </Text>
        </Box>
      </Box>
    </Card>
  );
}
