import { Outlet } from "react-router-dom";
import { NavigationBar } from "src/components/organisms/NavigationBar";

export function NavbarContainer() {
  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
}
