export type Customer = {
  id: string;
  name: string;
};

export type CustomerPreference = {
  description: string;
  label: string;
  notificationType: CustomerPreferenceType;
  unsubscribed: boolean;
};

export enum CustomerPreferenceType {
  ALL_NOTIFICATIONS = "all_notifications",
  CONFIRMATION_CAE = "confirmation_corporate_action_election",
  CONFIRMATION_PROXY_VOTE = "confirmation_proxy_vote",
  MEETING_RESULT_PROXY_VOTE = "meeting_result_proxy_vote",
  REMINDER_PROXY_VOTE = "reminder_proxy_vote",
}
