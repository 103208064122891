import { Format, FormatType } from "src/types/Communication";

export function isDaysBeforeDate(date: string, daysBefore: number) {
  const eventDate = new Date(date);
  const dateBefore = new Date();
  dateBefore.setDate(dateBefore.getDate() + daysBefore);
  if (dateBefore <= eventDate) {
    return true;
  }
  return false;
}

const formatTypeCopyMap = {
  [FormatType.InPerson]: "in-person at the address above",
  [FormatType.Virtual]: "virtually via the URL",
  [FormatType.Telephone]: "remotely via telephone",
  [FormatType.Private]: "private",
};

export function getCardTitle(formats: Format[]) {
  const getMeetingTypes = formats.reduce(
    (acc: string[], format: Format, index) => {
      if (formatTypeCopyMap[format.format]) {
        if (formats.length > 1 && index === formats.length - 1) {
          acc.push(`or ${formatTypeCopyMap[format.format]}`);
        } else {
          acc.push(formatTypeCopyMap[format.format]);
        }
      }
      return acc;
    },
    []
  );

  return `Choose whether you would like to attend the meeting ${
    formats.length > 2 ? getMeetingTypes.join(", ") : getMeetingTypes.join(" ")
  }.`;
}
