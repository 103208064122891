import { COLORS, Text } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DocumentViewer } from "./components/DocumentViewer";

interface IResourceDialogProps {
  isOpen: boolean;
  name: string;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  url: string;
}

export function ResourceDialog({
  isOpen,
  name,
  onClose,
  url,
}: IResourceDialogProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const content = (
    <Stack height="100%">
      <Box
        sx={{
          py: 5,
          borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
        }}
      >
        <Text
          variant={isSmall ? "subtitle1" : "h5"}
          sx={{ flexGrow: 1, textAlign: "center" }}
        >
          {name}
        </Text>
        <IconButton
          sx={{ position: "absolute", top: "11px", right: "8px" }}
          onClick={onClose}
        >
          <Close sx={{ color: COLORS.BLACK }} />
        </IconButton>
      </Box>
      <DocumentViewer url={url} />
    </Stack>
  );

  if (isSmall) {
    return (
      <Drawer
        data-testid="resource-drawer"
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: { height: "100%", bottom: "-114px" },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Dialog
      data-testid="resource-dialog"
      fullScreen
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          height: "100%",
          width: "100%",
        },
      }}
      sx={{
        my: 6,
        mx: "auto",
        px: 6,
        maxWidth: "1576px",
      }}
    >
      {content}
    </Dialog>
  );
}
