import { IconName } from "@asayinc/component-library";

export function getMeetingIconName(format: string): IconName {
  switch (format) {
    case "in_person":
      return "LocationOutline";
    case "virtual":
      return "Link";
    case "telephone":
      return "CallOutline";
    case "private":
      return "LocationOutline";
    default:
      return "LocationOutline";
  }
}
