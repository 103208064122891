import { COLORS, IconButton, SayIcon, Text } from "@asayinc/component-library";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { LoadingConfirmationDialog } from "src/components/molecules/LoadingConfirmationDialog";
import { useBrokerQuery } from "src/store/warrenG/broker";
import { useCommunicationsQuery } from "src/store/warrenG/communication";
import { CommunicationRow } from "../CommunicationRow";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";

interface IVoteSuccessDialogProps {
  backLink: string;
  isOpen: boolean;
  issuerName: string;
  onClose: () => void;
}

export function VoteSuccessDialog({
  backLink,
  isOpen,
  issuerName,
  onClose,
}: IVoteSuccessDialogProps) {
  const theme = useTheme();
  const { brokerId, customerId } = useParams() as {
    brokerId: string;
    customerId: string;
  };
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const { isLoading: isLoadingNextComms, data: upcomingCommunications } =
    useCommunicationsQuery({
      brokerId,
      customerId,
      isOpen: true,
      isActionable: true,
    });
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoading = isBrokerLoading || isLoadingNextComms;
  const isLastMeeting = upcomingCommunications?.results.length === 0 || false;
  const maxWidth = isLastMeeting ? "320px" : isSmall ? "320px" : "640px";

  const handleEditClick = () => {
    track({
      name: TrackEventType.AllBallotsEditBallot,
      brokerId,
      customerId,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} disableScrollLock>
      {isLoading ? (
        <LoadingConfirmationDialog />
      ) : (
        <>
          <DialogTitle>
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ p: 8, mt: 3, maxWidth: maxWidth }}
            data-testid="vote-submitted-dialog"
          >
            <Stack justifyContent="center" alignItems="center">
              <SayIcon
                name={
                  isLastMeeting
                    ? "CelebrationFill"
                    : "ProposalGeneralThinOutline"
                }
                color={theme.palette.l4.main}
                size="48px"
              />
              <Text
                variant="h5"
                sx={{ mt: 8, color: theme.palette.l4.main }}
                data-testid="Title-success"
              >
                {isLastMeeting ? "Great job!" : "Vote submitted!"}
              </Text>
              {isLastMeeting && (
                <Text
                  variant="subtitle2"
                  sx={{
                    mt: 3,
                    color: theme.palette.l4.main,
                    textAlign: "center",
                  }}
                >
                  You just completed your last open vote up until now.
                </Text>
              )}
              <Text sx={{ mt: 6 }} variant="body2" data-testid="description">
                {issuerName} appreciates your vote. You will receive an email
                confirmation shortly.
              </Text>
              <Text
                variant="body3"
                emphasis="medium"
                sx={{ mt: 8 }}
                data-testid="change-ballot"
              >
                Changed your mind? Edit your ballots{" "}
                <Link data-testid="change-ballot-link" to={backLink}>
                  <strong
                    style={{ color: COLORS.P_MED_EMPHASIS }}
                    onClick={handleEditClick}
                  >
                    here.
                  </strong>
                </Link>
              </Text>
            </Stack>
            {!isLastMeeting && upcomingCommunications?.results[0] && (
              <Stack sx={{ mt: 8, borderTop: `1px solid ${COLORS.P_OUTLINE}` }}>
                <Text
                  variant="subtitle2"
                  sx={{ color: theme.palette.l4.main, pt: 8, mb: 3 }}
                >
                  Next
                </Text>
                <CommunicationRow
                  sx={{
                    p: 0,
                  }}
                  brokerId={brokerId}
                  communication={upcomingCommunications.results[0]}
                  communicationIndex={0}
                  isNextCommunication={true}
                />
              </Stack>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
