import { LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";

import { Communication } from "src/types";
import { ResourceRow } from "../ResourceRow";

interface IShareholderResourcesProps {
  communication: Communication | undefined;
  isLoading: boolean;
  isProxyCommunication: boolean | undefined;
}

export function ShareholderResources({
  communication,
  isLoading,
  isProxyCommunication,
}: IShareholderResourcesProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        mb: 8,
        p: 6,
        [theme.breakpoints.down("sm")]: {
          mb: isProxyCommunication ? 4 : 0,
        },
      }}
      data-testid="sidebar-card"
    >
      <Text
        variant="subtitle2"
        emphasis="high"
        sx={{ mb: 3, color: theme.palette.l4.main }}
        loadingProps={{ sx: { width: "120px" } }}
      >
        {isLoading ? undefined : "Shareholder resources"}
      </Text>
      {isLoading ? (
        <Box>
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
          <LoadingSkeleton sx={{ mb: 2 }} />
        </Box>
      ) : (
        <Text variant="body2" sx={{ mb: 4 }}>
          {`Official materials provided by the company to help inform your ${
            isProxyCommunication ? "vote" : "election"
          }`}
        </Text>
      )}
      {/* Only show when dissident info */}
      {/* <Text variant="subtitle2" emphasis="high" sx={{ mb: 2 }}>
        Management materials
      </Text> */}
      {isLoading ? (
        <Box>
          <Box sx={{ display: "flex", my: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
          <Box sx={{ display: "flex", mt: 4 }}>
            <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
            <LoadingSkeleton />
          </Box>
        </Box>
      ) : (
        communication?.documents.map((attachment, index) => {
          return (
            <ResourceRow
              key={`management-resource-${index}`}
              attachment={attachment}
            />
          );
        })
      )}
    </Box>
  );
}
