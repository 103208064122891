import { COLORS, Text } from "@asayinc/component-library";
import { Error } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";

export function ChoiceErrors() {
  const {
    formState: { errors },
  } = useFormContext();
  const choiceDetailErrors = errors?.choiceDetails;
  const messages: string[] = Array.isArray(choiceDetailErrors)
    ? choiceDetailErrors.reduce((acc, choiceDetailError) => {
        return [
          ...acc,
          ...Object.keys(choiceDetailError).map(
            (a) => choiceDetailError[a].message
          ),
        ];
      }, [])
    : [];

  return (
    <Stack sx={{ mt: 3 }}>
      {messages.map((message, index) => {
        return (
          <Stack sx={{ mt: index === 0 ? 0 : 1 }} direction="row" key={index}>
            <Error sx={{ height: "16px", color: COLORS.ERROR_DARK }} />
            <Text variant="body3" sx={{ color: COLORS.ERROR_DARK }}>
              {message}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
}
