import {
  COLORS,
  LoadingSkeleton,
  SayIcon,
  Text,
} from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { getProposals } from "./utils";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useBrokerQuery } from "src/store/warrenG/broker";

export function Proposals() {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const theme = useTheme();
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);

  const isLoading = isCommunicationLoading || isBrokerLoading;
  const proposals = communication ? getProposals(communication) : [];

  return (
    <Box
      sx={{
        mt: 8,
        px: 4,
        borderTop: `1px solid ${
          isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
        }`,
      }}
    >
      <Text
        variant="subtitle1"
        sx={{ mt: 6, mb: 3, color: theme.palette.l4.main }}
        loadingProps={{ sx: { width: "240px" } }}
      >
        {isLoading ? undefined : "What you'll vote on:"}
      </Text>
      {isLoading ? (
        <Stack sx={{}}>
          <Box sx={{ display: "flex", mb: 3.5 }}>
            <LoadingSkeleton sx={{ mr: 4, width: "24px" }} />
            <LoadingSkeleton sx={{}} />
          </Box>
          <Box sx={{ display: "flex" }}>
            <LoadingSkeleton sx={{ mr: 4, width: "24px" }} />
            <LoadingSkeleton sx={{}} />
          </Box>
        </Stack>
      ) : (
        proposals.map((proposal, index) => {
          return (
            <Box
              key={"proposal-" + index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mt: 3,
                mb: 3,
                "&:last-child": {
                  mb: 0,
                },
              }}
              data-testid="landing-page-proposal-blurb"
            >
              <Box sx={{ py: 1, mt: "-6px" }}>
                {proposal.icon ? (
                  <SayIcon name={proposal.icon} color={theme.palette.l4.main} />
                ) : null}
              </Box>
              <Text variant="body2" sx={{ ml: proposal.icon ? 4 : 10 }}>
                {proposal.proposal}
              </Text>
            </Box>
          );
        })
      )}
    </Box>
  );
}
