import { COLORS, Text } from "@asayinc/component-library";
import { Box, Radio, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { Communication, Proposal } from "src/types/Communication";

interface ISelectAllProposalsProps {
  communication: Communication;
  formValues: { [key: string]: string };
  groupedProposal: Proposal[];
  isAttendingMeeting: boolean;
  isPassedCutoffDate: boolean;
  onChange: (proposalId: string, value: string) => void;
  options: { label: string; value: string }[];
}

export function SelectAllProposals({
  communication,
  formValues,
  groupedProposal,
  isAttendingMeeting,
  isPassedCutoffDate,
  onChange,
  options,
}: ISelectAllProposalsProps) {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const theme = useTheme();

  const handleOnChange = (value: string) => {
    if (!isPassedCutoffDate && !isAttendingMeeting) {
      track({
        name: TrackEventType.CommunicationDetailsSelectAll,
        proposalIds: groupedProposal.map((proposal) => proposal.id),
        choice: value,
        brokerId,
        communicationId,
        customerId: communication?.customer.id,
      });
      groupedProposal.forEach((proposal) => {
        onChange(proposal.id, value);
      });
    }
  };

  const isChecked = (value: string) => {
    return groupedProposal.every(
      (proposal) => formValues[proposal.id] === value
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        mt: 6,
        pb: 6,
        borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      }}
    >
      {options.map((option, index) => {
        const { label, value } = option;
        return (
          <Box
            key={index}
            onClick={() => handleOnChange(value)}
            sx={{
              border: `1px solid ${
                isChecked(value) ? theme.palette.l4.main : "#E0E0E0"
              }`,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              flexBasis: 0,
              mr: 3,
              py: 1,
              pl: 2,
              cursor: isPassedCutoffDate ? "auto" : "pointer",
              "&:last-child": {
                mr: 0,
              },
              color: isChecked(value) ? theme.palette.l4.main : "black",
              [theme.breakpoints.down("md")]: {
                mr: 0,
                mt: 2,
                "&:first-of-type": {
                  mt: 0,
                },
              },
            }}
          >
            <Radio
              size="small"
              checked={isChecked(value)}
              sx={{
                color: "#E0E0E0",
                "&, &.Mui-checked": {
                  color: isChecked(value)
                    ? `${theme.palette.l4.main}`
                    : "#E0E0E0",
                },
              }}
              disabled={isPassedCutoffDate || isAttendingMeeting}
            />
            <Text
              variant="body2"
              sx={{
                color:
                  isPassedCutoffDate || isAttendingMeeting
                    ? COLORS.P_DISABLED
                    : isChecked(value)
                    ? theme.palette.l4.main
                    : COLORS.BLACK,
              }}
            >
              {label}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
}
