import { LoadingSkeleton, SayIcon, Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";

import { formatDate } from "src/utils/formatDate";
import { ProxyCommunication } from "src/types";
import { Card } from "../Card";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { getMeetingIconName } from "src/pages/CommunicationDetails/components/MeetingInformation/utils";
import { getCardTitle } from "./utils";

export function MeetingInfoCard() {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading, data: communication } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });
  const theme = useTheme();

  if (isLoading) {
    return (
      <Card sx={{ mb: 6, width: "100%" }}>
        <Box sx={{ display: "flex", my: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
      </Card>
    );
  }

  const {
    proxyEvent: { meeting },
  } = communication as ProxyCommunication;
  const { formats, meetingDate, meetingTimezone } = meeting;

  const handleOnFormatClick = (meetingFormat: string, value: string) => {
    if (meetingFormat === "virtual") {
      window.open(value, "_blank");
    }
  };

  return (
    <Card
      sx={{ mb: 8 }}
      title="Meeting details"
      description={getCardTitle(formats)}
    >
      <Box
        sx={{
          display: "flex",
          color: theme.palette.l4.main,
          flexGrow: 1,
          borderBottom: "1px solid #E0E0E0",
          pb: 4,
          alignItems: "center",
        }}
      >
        <Box sx={{ height: "24px " }}>
          <SayIcon name="Calendar" color={theme.palette.l4.main} />
        </Box>
        <Text
          sx={{
            ml: 3,
          }}
          variant="body2"
          data-testid="meeting-date"
        >
          {formatDate({ date: meetingDate, timezone: meetingTimezone })}
          {" at "}
          {formatDate({
            date: meetingDate,
            format: "h:mm A",
            timezone: meetingTimezone,
          })}{" "}
          {meetingTimezone}
        </Text>
      </Box>
      {formats.map((format, index) => {
        const { format: meetingFormat, value } = format;
        return (
          <Box
            data-testid={`meeting-info-format-${meetingFormat}`}
            key={index}
            sx={{
              display: "flex",
              color: theme.palette.l4.main,
              alignItems: "center",
              borderBottom: "1px solid #E0E0E0",
              "&:last-child": {
                borderBottom: "none",
                pb: 0,
              },
              py: 4,
            }}
          >
            <SayIcon
              name={getMeetingIconName(meetingFormat)}
              color={theme.palette.l4.main}
            />
            <Box>
              <Text
                variant="body2"
                sx={{
                  ml: 3,
                  cursor: meetingFormat === "virtual" ? "pointer" : "default",
                  textUnderlineOffset: "4px",
                  wordBreak: "break-word",
                  "&:hover": {
                    textDecoration:
                      meetingFormat === "virtual" ? "underline" : "none",
                  },
                }}
                onClick={() => handleOnFormatClick(meetingFormat, value)}
              >
                {value}
              </Text>
              {meetingFormat === "virtual" && (
                <Text sx={{ ml: 3 }} variant="body3" emphasis="medium">
                  This link may not be active until the day of the meeting.
                </Text>
              )}
            </Box>
          </Box>
        );
      })}
    </Card>
  );
}
