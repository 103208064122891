import { Format, FormatType } from "src/types/Communication";

const formatTypeCopyMap = {
  [FormatType.InPerson]: "in-person",
  [FormatType.Virtual]: "online",
  [FormatType.Telephone]: "via telephone",
  [FormatType.Private]: "private",
};

// One format type example: "This meeting can be attended in-person."
// Two format types example: "This meeting can be attended in-person or online."
// Three format types example: "This meeting can be attended in-person, online, or via telephone."
export function getCardTitle(formats: Format[]) {
  const getMeetingTypes = formats.reduce(
    (acc: string[], format: Format, index) => {
      if (formatTypeCopyMap[format.format]) {
        if (formats.length > 1 && index === formats.length - 1) {
          acc.push(`or ${formatTypeCopyMap[format.format]}`);
        } else {
          acc.push(formatTypeCopyMap[format.format]);
        }
      }
      return acc;
    },
    []
  );

  return `This meeting can be attended ${
    formats.length > 2 ? getMeetingTypes.join(", ") : getMeetingTypes.join(" ")
  }.`;
}
