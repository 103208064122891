import { Text } from "@asayinc/component-library";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  CustomerPreference,
  CustomerPreferenceType,
} from "src/store/warrenG/customer/types";

interface IPreferenceRowProps {
  name: string;
  onUpdate: (notificationType: CustomerPreferenceType, value: boolean) => void;
  preference: CustomerPreference;
}

export function PreferenceRow({
  name,
  onUpdate,
  preference,
}: IPreferenceRowProps) {
  const { description, label: title, notificationType } = preference;
  const { control } = useFormContext();
  const label = (
    <Stack sx={{ mt: 1.5, ml: 1 }}>
      <Text variant="subtitle1" sx={{ width: "100%", mb: 2 }}>
        {title}
      </Text>
      {description && <Text variant="body2">{description}</Text>}
    </Stack>
  );

  return (
    <Box
      sx={{
        py: 6,
        pt: 4,
        borderBottom: "1px solid #E0E0E0",
        flexGrow: 1,
        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      <FormControlLabel
        sx={{ width: "100%", alignItems: "flex-start" }}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                sx={{ p: 2, ml: 2 }}
                size="small"
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  // Side effect
                  // When we select "Unsubscribe from all" we want to update all the other preferences
                  // When we select any other preference we want to update "Unsubscribe from all"
                  onUpdate(notificationType, e.target.checked);
                }}
              />
            )}
          />
        }
        label={label}
      />
    </Box>
  );
}
