import {
  ButtonV2,
  LoadingSkeleton,
  SayIcon,
  Text,
} from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Card } from "src/pages/AttendMeeting/components/Card";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { getRegistrationSuccessfulCardContent } from "./utils";

export function RegistrationSuccessCard() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { brokerId, communicationId } = useParams() as {
    brokerId: string;
    communicationId: string;
  };
  const { data: communication, isLoading } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  if (isLoading) {
    return (
      <Card sx={{ mb: 6, width: "100%" }}>
        <Box sx={{ display: "flex", my: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <LoadingSkeleton sx={{ width: "24px", mr: 4 }} />
          <LoadingSkeleton />
        </Box>
      </Card>
    );
  }

  // TODO: if there is no meetingAttendance then navigate to ballot page
  // user probably went directly to this link
  if (!communication?.meetingAttendance) {
    navigate(
      `/brokers/${brokerId}/communications/${communicationId}/details` +
        location.search
    );
    return null;
  }

  const handleDownloadClick = () => {
    track({
      name: TrackEventType.AttendMeetingConfirmationDownloadProxy,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    if (communication?.meetingAttendance?.document) {
      window.open(communication.meetingAttendance.document, "_blank");
    }
  };

  const showLegalProxy =
    communication.meetingAttendance.format === "in_person" &&
    communication.meetingAttendance.legalProxy &&
    communication.meetingAttendance.document;

  return (
    <Card sx={{ p: 8 }}>
      <Stack alignItems="center">
        <Box>
          <SayIcon
            name="HowToRegOutline"
            size="48px"
            color={theme.palette.l4.main}
          />
        </Box>
        <Text
          variant="h5"
          sx={{ mt: 8, color: theme.palette.l4.main }}
          data-testid="attend-complete-title"
        >
          Registration successful!
        </Text>

        <Text
          variant="body2"
          sx={{ mt: 6 }}
          data-testid="attend-complete-description"
        >
          {getRegistrationSuccessfulCardContent(communication)}
        </Text>
        {showLegalProxy && (
          <Stack sx={{ width: "100%" }} onClick={handleDownloadClick}>
            <ButtonV2
              sx={{ mt: 8 }}
              variant="primary"
              data-testid="download-legal-proxy"
            >
              <FileDownload sx={{ mr: 2 }} /> Download Legal Proxy
            </ButtonV2>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
