import {
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import humps from "humps";

export function getBaseQueryWithCamelize(
  baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>
) {
  const baseQueryWithCamelize: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions = {}) => {
    // decamelize request fields
    if (typeof args !== "string") {
      args.body = humps.decamelizeKeys(args.body);
    }
    const result = await baseQuery(args, api, extraOptions);
    if (result.data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.data = humps.camelizeKeys(result.data as any);
    }
    return result;
  };
  return baseQueryWithCamelize;
}
