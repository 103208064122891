import { ButtonV2 } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { EventHeader } from "../Communication/components/EventHeader";
import { RegistrationSuccessCard } from "./components/RegistrationSuccessCard";
import { AttendMeetingDetailsCard } from "./components/AttendMeetingDetailsCard";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { TrackEventType } from "src/analytics/types";
import { track } from "src/analytics";

export function AttendMeetingConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { brokerId, communicationId } = useParams() as {
    brokerId: string;
    communicationId: string;
  };
  const { data: communication, isLoading } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });
  const theme = useTheme();

  const handleContinueVoting = () => {
    track({
      name: TrackEventType.AttendMeetingConfirmationBackToBallot,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    navigate(
      `/brokers/${brokerId}/communications/${communicationId}/details` +
        location.search
    );
  };

  const handleBackToBallots = () => {
    track({
      name: TrackEventType.AttendMeetingConfirmationToAllBallots,
      brokerId,
      communicationId,
      customerId: communication?.customer.id,
    });
    // Store the search params in sessionStorage
    // for when the user returns to the same communication
    sessionStorage.setItem(communicationId, location.search);
    navigate(`/brokers/${brokerId}/customers/${communication?.customer.id}`);
  };

  return (
    <Stack alignItems="center" mt={12} height="100%">
      <Box
        sx={{
          width: "100%",
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            borderBottom: `6px solid ${theme.palette.l1.main}`,
          },
        }}
      />
      <EventHeader sx={{ py: 8, px: 5, mb: 8 }} hideBanner />
      <Stack
        sx={{
          maxWidth: "752px",
          width: "100%",
          flexGrow: 1,
          [theme.breakpoints.down("sm")]: {
            px: 4,
          },
        }}
      >
        <RegistrationSuccessCard />
        <AttendMeetingDetailsCard />
        <Stack
          sx={{
            position: "sticky",
            bottom: 0,
            right: 0,
          }}
        >
          <GradientBlock height="20px" />
          <Stack
            sx={{
              backgroundColor: "#f0f0f0",
              pb: 8,
              [theme.breakpoints.down("sm")]: { pb: 6 },
            }}
          >
            <ButtonV2
              variant="primary"
              disabled={isLoading}
              onClick={handleContinueVoting}
              data-testid="attendMeeting-redirect-button"
            >
              {communication?.meetingAttendance?.legalProxy
                ? "Preview ballot"
                : "Continue voting"}
            </ButtonV2>
            {communication?.meetingAttendance?.legalProxy && (
              <ButtonV2
                sx={{ mt: 2 }}
                variant="secondary"
                disabled={isLoading}
                onClick={handleBackToBallots}
                data-testid="view-all-ballots"
              >
                View all ballots
              </ButtonV2>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
