import { ButtonV2, Text } from "@asayinc/component-library";
import { Dialog, DialogContent, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { track } from "src/analytics";
import { TrackEventType } from "src/analytics/types";
import { useTrackingParams } from "src/hooks/useTrackingParams";

import { useDeleteVoteResultMutation } from "src/store/warrenG/communication";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";

interface IWithdrawDialogProps {
  customerId: string;
  isOpen: boolean;
  issuerName: string;
  onClose: () => void;
}

export function WithdrawDialog({
  customerId,
  isOpen,
  issuerName,
  onClose,
}: IWithdrawDialogProps) {
  const trackingParams = useTrackingParams();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const location = useLocation();
  const navigate = useNavigate();
  const [deleteVote, { isLoading: isDeletingVote }] =
    useDeleteVoteResultMutation();

  const handleWithdraw = async () => {
    try {
      await deleteVote({ brokerId, communicationId, ...trackingParams });
      track({
        name: TrackEventType.CommunicationDetailsConfirmWithdraw,
        brokerId,
        communicationId,
        customerId,
      });
      // Store the search params in sessionStorage
      // for when the user returns to the same communication
      sessionStorage.setItem(communicationId, location.search);
      navigate(`/brokers/${brokerId}/customers/${customerId}`, {
        state: {
          backLink:
            `/brokers/${brokerId}/communications/${communicationId}/details` +
            location.search,
          previousIssuerName: issuerName,
          isWithdrawn: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog onClose={onClose} open={isOpen} disableScrollLock>
      <DialogContent sx={{ p: 8, maxWidth: "320px" }}>
        <Text variant="subtitle1" sx={{ mb: 4 }}>
          Withdraw my vote
        </Text>
        <Text variant="body1" sx={{ mb: 6 }}>
          Are you sure you would like to withdraw your vote? By withdrawing your
          vote it will not be counted at the meeting.
        </Text>
        <Stack>
          <ButtonV2
            data-testid="confirm-button-withdraw"
            variant="warning"
            size="small"
            onClick={handleWithdraw}
            loading={isDeletingVote}
          >
            Withdraw vote
          </ButtonV2>
          <ButtonV2
            data-testid="cancel-button"
            variant="warningSecondary"
            sx={{ mt: 3 }}
            onClick={onClose}
            size="small"
            disabled={isDeletingVote}
          >
            Cancel
          </ButtonV2>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
