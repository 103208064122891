import { COLORS, HookTextField } from "@asayinc/component-library";
import { SxProps, useTheme } from "@mui/material";
import { useEffect } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { FormErrorInfo } from "src/pages/CommunicationDetails/components/CorporateActionForm/ElectionChoice/ElectionChoice";

interface IStandardInputFieldProps {
  autoFocusOnClick?: boolean;
  inline?: boolean;
  isInFocus?: boolean;
  name: string;
  onClick?: () => void;
  onError?: (formError: FormErrorInfo) => void;
  placeholder: string;
  rules?: RegisterOptions;
  sx?: SxProps;
}

export function StandardInputField({
  autoFocusOnClick = false,
  inline = true,
  isInFocus = true,
  name,
  onClick,
  onError,
  placeholder,
  rules,
  sx,
}: IStandardInputFieldProps) {
  const { clearErrors, control, getFieldState, getValues, setFocus } =
    useFormContext();
  const fieldState = getFieldState(name);
  const { error } = fieldState;
  const theme = useTheme();

  useEffect(() => {
    if (isInFocus && autoFocusOnClick) {
      setFocus(name);
    }
  }, [isInFocus, autoFocusOnClick, setFocus, name]);

  useEffect(() => {
    if (error && onError) {
      const value = getValues(name);
      onError({
        inputName: name,
        errorMessage: error.message || "",
        errorType: error.type,
        value,
      });
    }
  }, [error, getValues, name, onError]);

  const handleOnFocus = () => {
    clearErrors(name);
  };

  const inputUnderlineColor = isInFocus
    ? error
      ? COLORS.ERROR_DARK
      : theme.palette.l4.main
    : "black";

  return (
    <HookTextField
      sx={{
        width: "50px",
        height: "20px",
        "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: `1px solid ${inputUnderlineColor}`,
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${inputUnderlineColor}`,
          transition: "none",
        },
        "& .MuiInput-underline:after": {
          borderBottom: `1px solid ${inputUnderlineColor}`,
          transition: "none",
        },
        ...sx,
      }}
      inputProps={{
        style: {
          padding: 0,
          fontSize: "14px",
          height: "20px",
          caretColor: inputUnderlineColor,
          color: error ? COLORS.ERROR_DARK : theme.palette.l4.main,
        },
        sx: {
          "&::placeholder": {
            textAlign: "center",
          },
        },
      }}
      wrapperSx={
        inline
          ? {
              display: "inline-block",
              ">div": {
                display: "inline-block",
              },
            }
          : {}
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      control={control as any}
      name={name}
      placeholder={placeholder}
      rules={rules}
      size="small"
      type="number" // Only number usecase for now, make this a prop when necessary
      variant="standard"
      showErrors={false} // Since everything is inline we want to only highlight red for errors
      onClick={onClick}
      onFocus={handleOnFocus}
    />
  );
}
