import { Communication, ProxyCommunication } from "src/types";

export function isProxyCommunication(communication: Communication) {
  return communication.type !== "corporate_action_election";
}

export function getGroupedProposals(communication: Communication) {
  if (communication.type === "corporate_action_election") {
    return [];
  }
  const proxyComm = communication as ProxyCommunication;
  const { proposals } = proxyComm;

  return proposals.reduce((groupedProposals, proposal) => {
    const { groupNumber } = proposal;
    if (!groupedProposals[groupNumber]) {
      groupedProposals[groupNumber] = [];
    }
    groupedProposals[groupNumber].push(proposal);
    return groupedProposals;
  }, {} as Record<string, ProxyCommunication["proposals"]>);
}

export function getMenuSections(communication: Communication) {
  if (communication.type === "corporate_action_election") {
    return [
      {
        title: "Elections",
        id: "election",
        menuItems: [
          {
            title: "TBD",
            id: "group-1",
          },
        ],
      },
    ];
  }

  const proposals = getGroupedProposals(communication);
  const menuItmes = Object.keys(proposals).map((key) => {
    const groupedProposal = proposals[key];
    const proposal = groupedProposal[0];
    return {
      title: proposal.title,
      id: `group-${key}`,
    };
  });
  const menuSections = [
    {
      title: "Proposals",
      id: "1",
      menuItems: menuItmes,
    },
  ];
  return menuSections;
}
