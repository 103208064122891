import { consumerApi } from "../rootApi";

type TrackingParams = {
  communicationId: string;
  messageId: string;
  type: "opened" | "voted";
};

export const messageApi = consumerApi.injectEndpoints({
  endpoints: (builder) => ({
    trackEvent: builder.query<null, TrackingParams>({
      query: ({ communicationId, messageId, type }) => ({
        url: `/messages/record-event/`,
        params: {
          communication_id: communicationId,
          message_id: messageId,
          type: type,
        },
      }),
    }),
  }),
});

export const { useTrackEventQuery, useLazyTrackEventQuery } = messageApi;
