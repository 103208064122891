import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FormatDateProps {
  date: string;
  format?: string;
  timezone?: string | null;
}

const formatDate = ({
  date,
  format = "MMMM D, YYYY",
  timezone,
}: FormatDateProps): string | null => {
  if (timezone) {
    return dayjs(date).tz(timezone).format(format);
  }
  return dayjs(date).format(format);
};

export { formatDate };
