import { Text } from "@asayinc/component-library";
import { Box, useTheme } from "@mui/material";
import { EmptyGeneralSVG } from "./EmptyGeneralSVG";

interface IGeneralEmptyStateProps {
  description?: string;
}

export function GeneralEmptyState({ description }: IGeneralEmptyStateProps) {
  const theme = useTheme();

  return (
    <Box
      data-testid="empty-state"
      sx={{
        mt: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          mt: 4,
        },
      }}
    >
      <EmptyGeneralSVG />
      {description && (
        <Text
          data-testid="empty-state-description"
          variant="subtitle1"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {description}
        </Text>
      )}
    </Box>
  );
}
