export interface Identify {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

export interface TrackEvent {
  name: TrackEventType;
  brokerId: string;
  communicationId?: string;
  customerId: string | undefined;
  [index: string]: string | string[] | boolean | number | undefined;
}

export enum TrackEventType {
  // Navigation bar events
  MyBallots = "NavigationBar.MyBallots",
  Profile = "NavigationBar.Profile",

  // Landing page events
  LandingPageToCommunicationDetails = "LandingPage.ToCommunicationDetails",

  // Communication details page events
  CommunicationDetailsDocument = "CommunicationDetails.Document",
  CommunicationDetailsRegisterToAttend = "CommunicationDetails.RegisterToAttend",
  CommunicationDetailsConfirmRegisterToAttend = "CommunicationDetails.ConfirmRegisterToAttend",
  CommunicationDetailsCancelRegisterToAttend = "CommunicationDetails.CancelRegisterToAttend",
  CommunicationDetailsSelectAll = "CommunicationDetails.SelectAll",
  CommunicationDetailsDownloadProxy = "CommunicationDetails.DownloadProxy",
  CommunicationDetailsSubmit = "CommunicationDetails.Submit",
  CommunicationDetailsWithdrawVote = "CommunicationDetails.WithdrawVote",
  CommunicationDetailsConfirmWithdraw = "CommunicationDetails.ConfirmWithdraw",
  CommunicationDetailsCancelWithdraw = "CommunicationDetails.CancelWithdraw",
  CommunicationDetailsBallotTab = "CommunicationDetails.BallotTab",
  CommunicationDetailsInfoTab = "CommunicationDetails.InfoTab",
  CommunicationDetailsFormError = "CommunicationDetails.FormError",

  // Attend meeting page events
  AttendMeetingSubmitRegistration = "AttendMeeting.SubmitRegistration",
  AttendMeetingBackToCommunicationDetails = "AttendMeeting.BackToCommunicationDetails",

  // Attend meeting confirmation page events
  AttendMeetingConfirmationDownloadProxy = "AttendMeetingConfirmation.DownloadProxy",
  AttendMeetingConfirmationBackToBallot = "AttendMeetingConfirmation.BackToBallot",
  AttendMeetingConfirmationToAllBallots = "AttendMeetingConfirmation.ToAllBallots",

  // All ballots page events
  AllBallotsOpenEvents = "AllBallots.OpenEvents",
  AllBallotsPastEvents = "AllBallots.PastEvents",
  AllBallotsVote = "AllBallots.Vote",
  AllBallotsUpdate = "AllBallots.Update",
  AllBallotsCheckResults = "AllBallots.CheckResults",
  AllBallotsEditBallot = "AllBallots.EditBallot",
  AllBallotsVoteNext = "AllBallots.VoteNext",

  // Election confirmation page
  ElectionConfirmationEdit = "ElectionConfirmation.Edit",
}
