import { Provider as SayProvider } from "@asayinc/component-library";
import { CssBaseline, PaletteColor, createTheme } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";

import { breakpoints } from "src/constants/breakpoints";
import { useBrokerQuery } from "src/store/warrenG/broker";

declare module "@mui/material/styles" {
  interface Palette {
    l1: PaletteColor;
    l2: PaletteColor;
    l3: PaletteColor;
    l4: PaletteColor;
    l5: PaletteColor;
    l6: PaletteColor;
    l7: PaletteColor;
    l8: PaletteColor;
  }
  interface PaletteOptions {
    l1?: PaletteColor;
    l2?: PaletteColor;
    l3?: PaletteColor;
    l4?: PaletteColor;
    l5?: PaletteColor;
    l6?: PaletteColor;
    l7?: PaletteColor;
    l8?: PaletteColor;
  }
}
export function BrokerContainer() {
  const { brokerId } = useParams() as { brokerId: string };
  const { data: broker } = useBrokerQuery(brokerId);
  const { palette } = createTheme();
  const { augmentColor } = palette;

  const defaultColor = augmentColor({ color: { main: "#000000" } });

  let customColors = {
    l1: defaultColor,
    l2: defaultColor,
    l3: defaultColor,
    l4: defaultColor,
    l5: defaultColor,
    l6: defaultColor,
    l7: defaultColor,
    l8: defaultColor,
  };

  if (broker) {
    customColors = {
      l1: augmentColor({
        color: { main: broker.theme.lightModeGenericColor },
      }),
      l2: augmentColor({
        color: { main: broker.theme.lightModeBannerBackgroundColor },
      }),
      l3: augmentColor({
        color: { main: broker.theme.lightModeBannerTextColor },
      }),
      l4: augmentColor({
        color: { main: broker.theme.lightModeCalloutColor },
      }),
      l5: augmentColor({
        color: { main: broker.theme.lightModeChipBackgroundColor },
      }),
      l6: augmentColor({
        color: { main: broker.theme.lightModePrimaryCtaTextColor },
      }),
      l7: augmentColor({
        color: { main: broker.theme.lightModeSecondaryCtaTextColor },
      }),
      l8: augmentColor({
        color: { main: broker.theme.lightModeSecondaryCtaBackgroundColor },
      }),
    };
  }

  return (
    <SayProvider
      customTheme={{
        palette: {
          primary: augmentColor({
            color: {
              main: broker?.theme.lightModeGenericColor || "#000000",
              contrastText:
                broker?.theme.lightModePrimaryCtaTextColor || "#000000",
            },
          }),
          secondary: augmentColor({
            color: {
              main:
                broker?.theme.lightModeSecondaryCtaBackgroundColor || "#000000",
              contrastText:
                broker?.theme.lightModeSecondaryCtaTextColor || "#000000",
            },
          }),
          ...customColors,
        },
        components: {
          MuiButton: {
            variants: [
              {
                props: { variant: "primary" },
                style: {
                  backgroundColor: broker?.theme.lightModeGenericColor,
                  color: broker?.theme.lightModePrimaryCtaTextColor,
                  "&:hover": {
                    backgroundColor: `${broker?.theme.lightModeGenericColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/8%) 0 0)",
                  },
                  "&:active": {
                    backgroundColor: `${broker?.theme.lightModeGenericColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/12%) 0 0)",
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: `${broker?.theme.lightModeGenericColor} !important`,
                  },
                },
              },
              {
                props: { variant: "secondary" },
                style: {
                  backgroundColor:
                    broker?.theme.lightModeSecondaryCtaBackgroundColor,
                  color: broker?.theme.lightModeSecondaryCtaTextColor,
                  "&:hover": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/8%) 0 0)",
                  },
                  "&:active": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/12%) 0 0)",
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                  },
                },
              },
              {
                props: { variant: "warningSecondary" },
                style: {
                  backgroundColor:
                    broker?.theme.lightModeSecondaryCtaBackgroundColor,
                  "&:hover": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/8%) 0 0)",
                  },
                  "&:active": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                    backgroundImage: "linear-gradient(rgb(0 0 0/12%) 0 0)",
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: `${broker?.theme.lightModeSecondaryCtaBackgroundColor} !important`,
                  },
                },
              },
              {
                props: { variant: "warning" },
                style: {
                  "&:hover": {
                    backgroundImage: "linear-gradient(rgb(0 0 0/8%) 0 0)",
                  },
                  "&:active": {
                    backgroundImage: "linear-gradient(rgb(0 0 0/12%) 0 0)",
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: "#B00020",
                  },
                },
              },
            ],
          },
          MuiChip: {
            variants: [
              {
                props: { variant: "primary" },
                style: {
                  backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  color: `${broker?.theme.lightModeGenericColor} !important`,
                  borderColor: "transparent !important",
                  "&:hover": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                  "&:active": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                },
              },
              {
                props: { variant: "secondary" },
                style: {
                  backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  color: `${broker?.theme.lightModeGenericColor} !important`,
                  borderColor: "transparent !important",
                  "&:hover": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                  "&:active": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                  "& .MuiTouchRipple-child": {
                    backgroundColor: `${broker?.theme.lightModeChipBackgroundColor} !important`,
                  },
                },
              },
            ],
          },
        },
      }}
      customBreakpoints={breakpoints}
    >
      <CssBaseline />
      <Outlet />
    </SayProvider>
  );
}
