import { TrackEvent } from "./types";

const track = ({ name, ...rest }: TrackEvent): void => {
  window.analytics.track(name, {
    product: "Voting App",
    ...rest,
  });
};

export { track };
