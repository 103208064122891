import { isProxyCommunication } from "src/pages/CommunicationDetails/utils";
import { Communication, ProxyCommunication } from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";

export function getAttendMeetingBannerText(communication: Communication) {
  const proxyCommunication = communication as ProxyCommunication;
  const { meetingAttendance, security, proxyEvent } = proxyCommunication;
  const { issuerName } = security;
  const { meeting } = proxyEvent;
  const { meetingDate, meetingTimezone } = meeting;

  if (meetingAttendance?.format === "in_person") {
    if (meetingAttendance?.legalProxy) {
      return `You registered to attend and vote at ${issuerName}'s meeting in-person. Please download, print, and bring the Legal Proxy Document below with you on the day of the meeting. Have a great time!`;
    }
    return `You registered to attend ${issuerName}'s meeting in-person at the location below. Have fun!`;
  }

  if (meetingAttendance?.format === "telephone") {
    if (meetingAttendance?.legalProxy) {
      return `You registered to attend and vote at ${issuerName}'s meeting via telephone. You will receive an email before the meeting date with all required information needed to attend and submit your vote at the meeting.`;
    }
    return `You registered to attend ${issuerName}'s meeting via telephone. You can join the meeting by dialing the number below on ${formatDate(
      { date: meetingDate, timezone: meetingTimezone }
    )} before the meeting starts.`;
  }

  if (meetingAttendance?.format === "virtual") {
    if (meetingAttendance?.legalProxy) {
      return `You registered to attend and vote at ${issuerName}'s virtual meeting. You will receive an email before the meeting date with all required information needed to attend and submit your vote at the meeting. `;
    }
    return `You registered to attend ${issuerName}'s virtual meeting. You can join the meeting by navigating to the URL below on ${formatDate(
      { date: meetingDate, timezone: meetingTimezone }
    )} before the meeting starts.`;
  }

  // We should never show this copy, but just in case we do, we'll have it here.
  return `You registered to attend ${issuerName}'s meeting. You will receive an email before the meeting date with all the materials needed to attend the meeting.`;
}

export function isContestedMeeting(communication: Communication) {
  if (isProxyCommunication(communication)) {
    const proxyCommunication = communication as ProxyCommunication;
    const {
      proxyEvent: { type },
    } = proxyCommunication;
    return (
      type === "contested_annual_meeting" ||
      type === "contested_special_meeting"
    );
  }
  return false;
}

export function getContestingParty(communication: Communication) {
  if (isProxyCommunication(communication)) {
    const proxyCommunication = communication as ProxyCommunication;
    const {
      proxyEvent: {
        meeting: { contestingParty },
      },
    } = proxyCommunication;
    return contestingParty;
  }
  return "";
}
